import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp, FaCrown } from 'react-icons/fa';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import FreeAnimation from './lottie/Free-lottie.json';
import premiumAnimation from './lottie/premium-lottie.json';
import BusinessAnimation from './lottie/business-lottie.json';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api';
import { useUser } from "./context";

const Membership = () => {
    const { user, login } = useUser();
    const [isUpgradingPremium, setIsUpgradingPremium] = useState(false);
    const [isUpgradingBusiness, setIsUpgradingBusiness] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [visibleBenefit, setVisibleBenefit] = useState(null);
    const [isBusiness, setIsBusiness] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user || !user.token) {
          navigate('/login');
        } else {
          // Fetch updated user data when the dashboard is visited
          const fetchUserData = async () => {
            try {
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${user.token}`
                }
              };
              const response = await api.get('/api/auth/info', config);
              if (response.status === 200) {
                const updatedUser = { ...user, ...response.data };
                login(updatedUser);

                // Set isBusiness based on accountType and isPremium
                if (updatedUser.accountType === 'business' && updatedUser.isPremium) {
                    setIsBusiness(true);
                } else {
                    setIsBusiness(false);
                }
              } else {
                console.error('Failed to fetch user data, status code:', response.status);
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          };
          fetchUserData();
        }
      }, [user, navigate, login]);

    const premiumBenefits = [
        {
            title: "Unlimited Donation Links",
            description: "Create as many donation links as you need to support various causes and initiatives."
        },
        {
            title: "Instant Withdrawals",
            description: "Get immediate access to your funds with our expedited withdrawal process."
        },
        {
            title: "Unlimited Volunteer Programs",
            description: "Join or organize an unlimited number of volunteer programs to expand your social impact."
        },
        {
            title: "Advanced Analytics",
            description: "Access detailed reports and analytics to track and optimize your fundraising efforts."
        },
        {
            title: "Priority Support",
            description: "Receive priority customer service with faster response times and dedicated support."
        },
        {
            title: "Networking Opportunities",
            description: "Connect with a network of like-minded individuals and organizations for collaboration and support."
        },
        {
            title: "Customizable Fundraising Pages",
            description: "Personalize your fundraising pages with advanced customization options for greater impact."
        },
        {
            title: "Promotional Features",
            description: "Get your campaigns and programs featured in our promotional channels for increased visibility."
        },
        {
            title: "Educational Resources",
            description: "Access exclusive webinars, tutorials, and guides to enhance your fundraising skills."
        }
    ];

    const businessBenefits = [
        {
            title: "Corporate Sponsorship Opportunities",
            description: "Gain access to exclusive corporate sponsorship opportunities to support your initiatives."
        },
        {
            title: "Dedicated Account Manager",
            description: "Receive personalized support from a dedicated account manager."
        },
        {
            title: "Advanced Fundraising Tools",
            description: "Utilize advanced tools to streamline your fundraising efforts and maximize donations."
        },
        {
            title: "Priority Feature Requests",
            description: "Get priority consideration for feature requests and new functionality."
        },
        {
            title: "Exclusive Networking Events",
            description: "Attend exclusive events designed for business tier members to network and collaborate."
        },
        {
            title: "Enhanced Visibility",
            description: "Benefit from enhanced visibility for your campaigns through our promotional channels."
        },
        {
            title: "Custom Reports",
            description: "Receive custom reports tailored to your specific fundraising needs."
        },
        {
            title: "Exclusive Grant Applications",
            description: "Gain eligibility to apply for special grants exclusively available to business members."
        },
        {
            title: "Event Invitations",
            description: "Receive invitations to exclusive events and conferences for networking and learning."
        },
        {
            title: "Recognition and Awards",
            description: "Become eligible for member recognition awards and certificates to showcase your achievements."
        }
    ];

    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        }
    }, [user, navigate]);

    // Function to toggle benefit visibility
    const toggleBenefit = (benefit) => {
        setVisibleBenefit(visibleBenefit === benefit ? null : benefit);
    };

    const handleUpgrade = async (tier) => {
        if (tier === 'premium') {
            setIsUpgradingPremium(true);
        } else if (tier === 'business') {
            setIsUpgradingBusiness(true);
        }

        try {
            const endpoint = tier === 'premium' ? '/api/auth/upgrade-membership' : '/api/auth/upgrade-business';
            const response = await api.patch(endpoint, {}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                }
            });

            if (response.status === 200) {
                const updatedUser = { ...user, isPremium: tier === 'premium', accountType: tier === 'business' ? 'business' : user.accountType };
                login(updatedUser);

                // Set isBusiness based on accountType and isPremium
                if (updatedUser.accountType === 'business' && updatedUser.isPremium) {
                    setIsBusiness(true);
                } else {
                    setIsBusiness(false);
                }

                toast.success(`Membership upgraded to ${tier.charAt(0).toUpperCase() + tier.slice(1)} successfully!`);
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 5000);
            } else {
                toast.error("Failed to upgrade membership.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred during the upgrade.");
        } finally {
            if (tier === 'premium') {
                setIsUpgradingPremium(false);
            } else if (tier === 'business') {
                setIsUpgradingBusiness(false);
            }
        }
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg ">
            <ToastContainer position="top-center" />
            {showConfetti && <Confetti />}
            <h2 className="text-lg font-bold mb-4">Membership</h2>
            <div className="flex flex-col md:flex-row justify-center items-stretch gap-4">
                {/* Free Tier Card */}
                <div className="border rounded-lg p-6 flex flex-col justify-between flex-1">
                    <div>
                        <h3 className="text-md font-extrabold mb-4 text-center">Free Tier</h3>
                        <div className="mb-4 text-center">
                            <Lottie animationData={FreeAnimation} style={{ width: 150, height: 150, margin: '0 auto' }} />
                        </div>
                        <p className="text-green-500 text-xl font-bold mb-4 text-center">Free</p>
                        <ul className="text-xs mb-6 text-center">
                            <li>Participate in 1 volunteer program</li>
                            <li>Create 1 donation link</li>
                            <li>No access to grants</li>
                        </ul>
                    </div>
                    <button className="bg-gray-300 text-gray-700 text-xs py-2 px-4 rounded hover:bg-gray-400 transition duration-300 w-full mt-4">
                        Stay on Free
                    </button>
                </div>

                {/* Premium Tier Card */}
                <div className="border rounded-lg p-6 flex flex-col justify-between flex-1">
                    <div>
                        <h3 className="text-md font-extrabold mb-4 text-center">Premium Tier</h3>
                        <div className="mb-4 text-center">
                            <Lottie animationData={premiumAnimation} style={{ width: 150, height: 150, margin: '0 auto' }} />
                        </div>
                        <p className="text-green-500 text-xl font-bold mb-4 text-center">50 Points/year</p>
                        <ul className="text-xs mb-6 text-center">
                            {premiumBenefits.map((benefit, index) => (
                                <li
                                    key={index}
                                    className="cursor-pointer mb-3 last:mb-0"
                                    onClick={() => toggleBenefit(benefit.title)}
                                >
                                    <strong>{benefit.title}</strong>
                                    <span className="inline-block ml-2">
                                        {visibleBenefit === benefit.title ? <FaChevronUp /> : <FaChevronDown />}
                                    </span>
                                    {visibleBenefit === benefit.title && <p className="mt-2">{benefit.description}</p>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button
                        onClick={() => handleUpgrade('premium')}
                        disabled={isUpgradingPremium || user.isPremium}
                        className={`w-full text-xs py-2 px-4 rounded transition duration-300 ${user.isPremium ? "bg-gray-400" : isUpgradingPremium ? "bg-emerald-300" : "bg-emerald-500 hover:bg-emerald-600"} text-white`}>
                        {isUpgradingPremium ? (
                            <>
                                <FaSpinner className="animate-spin mr-2 inline" />
                                Upgrading...
                            </>
                        ) : user.isPremium ? (
                            <>
                                <FaCrown className="mr-2 inline" /> {/* King icon */}
                                Upgraded
                            </>
                        ) : (
                            "Upgrade to Premium"
                        )}
                    </button>
                </div>

                {/* Business Tier Card */}
                <div className="border rounded-lg p-6 flex flex-col justify-between flex-1">
                    <div>
                        <h3 className="text-md font-extrabold mb-4 text-center">Business Tier</h3>
                        <div className="mb-4 text-center">
                            <Lottie animationData={BusinessAnimation} style={{ width: 150, height: 150, margin: '0 auto' }} />
                        </div>
                        <p className="text-green-500 text-xl font-bold mb-4 text-center">200 Points/year</p>
                        <ul className="text-xs mb-6 text-center">
                            {businessBenefits.map((benefit, index) => (
                                <li
                                    key={index}
                                    className="cursor-pointer mb-3 last:mb-0"
                                    onClick={() => toggleBenefit(benefit.title)}
                                >
                                    <strong>{benefit.title}</strong>
                                    <span className="inline-block ml-2">
                                        {visibleBenefit === benefit.title ? <FaChevronUp /> : <FaChevronDown />}
                                    </span>
                                    {visibleBenefit === benefit.title && <p className="mt-2">{benefit.description}</p>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button
                        onClick={() => handleUpgrade('business')}
                        disabled={isUpgradingBusiness || isBusiness}
                        className={`w-full text-xs py-2 px-4 rounded transition duration-300 ${isBusiness ? "bg-gray-400" : isUpgradingBusiness ? "bg-emerald-300" : "bg-emerald-500 hover:bg-emerald-600"} text-white`}>
                        {isUpgradingBusiness ? (
                            <>
                                <FaSpinner className="animate-spin mr-2 inline" />
                                Upgrading...
                            </>
                        ) : isBusiness ? (
                            <>
                                <FaCrown className="mr-2 inline" /> {/* King icon */}
                                Upgraded
                            </>
                        ) : (
                            "Upgrade to Business"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Membership;
