import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import { HiOutlineDownload } from 'react-icons/hi'; // Import the download icon
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import loadingAnimation from '../../lottie/loading.json';
import noRecordAnimation from '../../lottie/noLinks.json';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useUser } from "../../context";
import jsPDF from "jspdf";
import "jspdf-autotable"

const TransferDetail = ({ transferId, onBack }) => {
    const { user } = useUser();
    const [transferDetails, setTransferDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);




    useEffect(() => {
        const fetchTransferDetails = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/transfers/transfer/${transferId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setTransferDetails(response.data);
                } else {
                    setError('Failed to fetch transfer details');
                }
            } catch (error) {
                console.error("Error fetching transfer details:", error);
                setError('An error occurred while fetching transfer details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchTransferDetails();
    }, [transferId, user.token]);

    // Determine the correct name to greet
    const isSender = user?._id === transferDetails?.sender._id;
    const nameToGreet = isSender
        ? transferDetails?.receiverFirstName.charAt(0).toUpperCase() + transferDetails?.receiverFirstName.slice(1)
        : transferDetails?.senderFirstName.charAt(0).toUpperCase() + transferDetails?.senderFirstName.slice(1);

    const generateReceipt = async (transferDetails) => {
        setIsGenerating(true);
        const doc = new jsPDF();
        // Set the opacity for the watermark
        doc.setGState(new doc.GState({ opacity: 0.2 }));

        // Get the current year
        const currentYear = new Date().getFullYear();

        // Watermark Text
        const watermarkText = `Verdant Charity ${currentYear}`;
        doc.setFontSize(10);
        doc.setTextColor(150, 150, 150); // Gray color for watermark text
        doc.setFont("helvetica", "bold");

        // Draw the watermark multiple times on the page
        for (let i = 0; i < doc.internal.pageSize.getHeight(); i += 20) { // Decreased Y spacing
            for (let j = 0; j < doc.internal.pageSize.getWidth(); j += 50) { // Decreased X spacing
                doc.text(watermarkText, j, i, { angle: 45 });
            }
        }

        // Reset the opacity back to full for the rest of the document
        doc.setGState(new doc.GState({ opacity: 1 }));

        // Fetch images and convert to Base64 format
        const paperPlaneImage = await getImageBase64('https://res.cloudinary.com/dx6jw8k0m/image/upload/v1709472568/send-money__1_-removebg-preview_hg9tip.png');
        const barcodeImage = await getImageBase64('https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg');

        // Receipt Title and Subtitle
        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.setTextColor(34, 139, 34);
        doc.text("Verdant Charity Transfer Receipt", doc.internal.pageSize.getWidth() / 2, 20, { align: "center" });

        doc.setFont("helvetica", "italic");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text("Empowering Change, One Transaction at a Time", doc.internal.pageSize.getWidth() / 2, 30, { align: "center" });
        doc.setFont("helvetica", "normal"); // Reset to normal for subsequent text


        // Add paper plane image and a horizontal line
        doc.addImage(paperPlaneImage, 'PNG', doc.internal.pageSize.getWidth() / 2 - 20, 40, 40, 30);
        doc.setLineWidth(0.2);
        doc.line(20, 75, doc.internal.pageSize.getWidth() - 20, 75);

        // Personalized Greeting
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.setTextColor(0, 112, 186); // Blue color for the greeting
        doc.text(`Hi ${nameToGreet},`, doc.internal.pageSize.getWidth() / 2, 90, { align: "center" });

        // Square for Total Amount
        const squareX = doc.internal.pageSize.getWidth() / 2 - 60;
        const squareY = 100;
        doc.setDrawColor(0, 112, 186);
        doc.setFillColor(0, 112, 186);
        doc.rect(squareX, squareY, 40, 40, 'F');

        // Text inside the square
        doc.setFontSize(10);
        doc.setTextColor(255, 255, 255);

        const totalAmountText = "Total Amount:";
        const totalAmountTextWidth = doc.getStringUnitWidth(totalAmountText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const totalAmountTextX = squareX + (40 - totalAmountTextWidth) / 2; // Center the text in the square

        doc.text(totalAmountText, totalAmountTextX, squareY + 15);

        doc.setFontSize(14); // Set font size for the amount
        const amountText = `${transferDetails.currency} ${transferDetails.amount}`;
        const amountTextWidth = doc.getStringUnitWidth(amountText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const amountTextX = squareX + (40 - amountTextWidth) / 2; // Center the amount text in the square

        doc.text(amountText, amountTextX, squareY + 25); // Reduced space between the texts

        // Transaction Details next to the square
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "normal");
        const detailsX = squareX + 50;
        let detailsStartY = squareY;
        doc.text(`Transaction ID: ${transferDetails.transactionId}`, detailsX, detailsStartY += 1);
        doc.text(`Sender: ${transferDetails.senderFirstName}`, detailsX, detailsStartY += 10);
        doc.text(`Receiver: ${transferDetails.receiverFirstName}`, detailsX, detailsStartY += 10);
        doc.text(`Status: ${transferDetails.status}`, detailsX, detailsStartY += 10);
        doc.text(`Date: ${new Date(transferDetails.createdAt).toLocaleString()}`, detailsX, detailsStartY += 10);

        // Barcode image
        doc.addImage(barcodeImage, 'PNG', doc.internal.pageSize.getWidth() / 2 - 80, detailsStartY + 20, 160, 30);

        // Footer
        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        const footerText = "If you encounter any issues, please contact support@verdantcharity.org. " +
            "Payment is secured with DLocal. Ravel Global Pay, Apt. 992, 54072 Larson Stravenue, Port Kymside, IA 70661-2925. " +
            "For support: support@verdantcharity.org | Hotline: +1 385 233 9891";
        doc.text(footerText, 20, doc.internal.pageSize.getHeight() - 30, { maxWidth: 180 });

        // Transaction ID on the top right corner
        const transactionId = `${nameToGreet}- ${transferDetails.transactionId}`;
        const transactionIdWidth = doc.getStringUnitWidth(transactionId) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.text(transactionId, doc.internal.pageSize.getWidth() - transactionIdWidth - 10, 5);


        doc.save(`Transfer_Receipt_${transferDetails.transactionId}.pdf`);
        setIsGenerating(false);
    };

    const getImageBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };


    const statusClasses = (status) => {
        switch (status) {
            case 'pending':
            case 'processing':
                return 'bg-yellow-200 text-yellow-800';
            case 'completed':
                return 'bg-green-200 text-green-800';
            case 'failed':
                return 'bg-red-200 text-red-800';
            case 'cancelled':
                return 'bg-gray-200 text-gray-800';
            default:
                return 'bg-gray-200 text-gray-800';
        }
    };


    if (isLoading) {
        return (
            <div className="flex justify-center items-center">
                <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
            </div>
        );
    }

    if (!transferDetails || error) {
        return (
            <div className="flex flex-col justify-center items-center">
                <Lottie animationData={noRecordAnimation} style={{ width: 200, height: 200 }} />
                <p className="mt-4 text-sm font-semibold text-gray-600">{error || "No transfer details found."}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg">
            <div className="border-b border-dotted pb-4">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <button onClick={onBack} className="flex items-center justify-center p-2 text-emerald-600 hover:text-emerald-800 transition-colors duration-150 ease-in-out mb-4 lg:mb-0 lg:mr-4">
                        <span className="inline-flex items-center justify-center p-2 mr-2 rounded-full border border-green-600 bg-green-100 hover:bg-green-200">
                            <AiOutlineArrowLeft />
                        </span>
                        Back
                    </button>
                    <p className="text-xs text-center text-gray-600 flex-1">Please review the details of your transfer below.</p>
                </div>
            </div>
            <div className="border p-4 rounded-lg">
                <div className="text-xs flex justify-between mb-2">
                    <strong>Transaction ID:</strong>
                    <span>{transferDetails.transactionId}</span>
                </div>
                <div className="text-xs flex justify-between mb-2">
                    <strong>Date & Time:</strong>
                    <span>{new Date(transferDetails.createdAt).toLocaleString()}</span>
                </div>
                <div className="text-xs flex justify-between mb-2">
                    <strong>Amount:</strong>
                    <span>{transferDetails.amount} {transferDetails.currency}</span>
                </div>
                <div className="text-xs flex justify-between mb-2">
                    <strong>Sender:</strong>
                    <span>{transferDetails.senderFirstName}</span>
                </div>
                <div className="text-xs flex justify-between mb-2">
                    <strong>Receiver:</strong>
                    <span>{transferDetails.receiverFirstName}</span>
                </div>
                {transferDetails.status && (
                    <div className="text-xs flex justify-between mb-2">
                        <strong>Status:</strong>
                        <span className={`px-2 rounded-full ${statusClasses(transferDetails.status)}`}>
                            {transferDetails.status}
                        </span>
                    </div>
                )}

                <div className="border-t border-dotted border-gray-400 pt-4 mt-4"></div> {/* Dotted border */}
                <button
                    className={`mt-4 px-4 py-2 flex justify-center items-center ${isGenerating ? 'bg-gray-500' : 'bg-blue-500'} text-white text-xs rounded hover:bg-blue-700 transition duration-150 ease-in-out w-full sm:w-auto`}
                    onClick={() => generateReceipt(transferDetails)}
                    disabled={isGenerating}
                >
                    {isGenerating ? (
                        <>
                            <AiOutlineLoading3Quarters className="animate-spin mr-2" /> Generating...
                        </>
                    ) : (
                        <>
                            <HiOutlineDownload className="mr-2" /> Download Receipt
                        </>
                    )}
                </button>

            </div>
        </div>
    );
};

export default TransferDetail;
