import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const DonationLinks = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [donationLinks, setDonationLinks] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [donationsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [linkToDelete, setLinkToDelete] = useState(null);

    useEffect(() => {
        fetchDonationLinks();
    }, [currentPage, searchTerm]);

    const fetchDonationLinks = async () => {
        setLoading(true);
        const queryParams = { page: currentPage, limit: donationsPerPage };

        if (searchTerm) {
            queryParams.uniqueIdentifier = searchTerm;
        }

        try {
            const response = await api.get('/api/admin/all-donation-links', {
                params: queryParams,
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setDonationLinks(response.data.donationLinks);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching donation links: ", error);
        }
        setLoading(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchDonationLinks();
    };

    const handleDeleteLinkClick = (link) => {
        setLinkToDelete(link);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (linkToDelete) {
            try {
                await api.delete(`/api/admin/all-donation-links/${linkToDelete._id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                toast.success("Donation link deleted successfully");
                setShowDeleteModal(false);
                fetchDonationLinks();
            } catch (error) {
                toast.error("Failed to delete donation link");
            }
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'active':
                return 'text-green-500';
            case 'approved':
               return 'text-green-500';
            case 'completed':
                return 'text-blue-500';
            case 'inactive':
                return 'text-gray-500';
            case 'cancelled':
                return 'text-red-500';
            case 'rejected':
                return 'text-red-700';
            case 'test':
                return 'text-yellow-500';
            default:
                return '';
        }
    };

    const getPagination = () => {
        const delta = 1;
        const range = [];
        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }
        if (currentPage - delta > 2) {
            range.unshift("...");
        }
        if (currentPage + delta < totalPages - 1) {
            range.push("...");
        }
        range.unshift(1);
        if (totalPages !== 1) range.push(totalPages);

        return range.map((page, index) => page === "..." ? <span key={index} className="px-2">...</span> : <button key={index} onClick={() => setCurrentPage(page)} className={`px-2 py-1 ${page === currentPage ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}>{page}</button>);
    };

    return (
        <div className="container mx-auto px-4 pb-20">
            <ToastContainer />
            <form onSubmit={handleSearch} className="mb-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-3">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by unique identifier..."
                    className="border p-2 rounded text-xs flex-grow"
                />
                <button type="submit" className="border p-2 rounded bg-emerald-500 text-white text-xs">
                    Search
                </button>
            </form>
            <div className="overflow-x-auto">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon icon={faSpinner} spin className="text-2xl" />
                    </div>
                ) : (
                    <table className="w-full border-collapse text-xs">
                        <thead>
                            <tr className="bg-gray-200 text-xs">
                                <th className="border p-2">Created At</th>
                                <th className="border p-2">Name</th>
                                <th className="border p-2">Unique Identifier</th>
                                <th className="border p-2">Title</th>
                                <th className="border p-2">Target Amount</th>
                                <th className="border p-2">Total Donations</th>
                                <th className="border p-2">Status</th>
                                <th className="border p-2 text-center">View</th>
                                <th className="border p-2 text-center">Owner</th>
                                <th className="border p-2 text-center">Edit</th>
                                <th className="border p-2 text-center">Delete</th>
                            </tr>
                        </thead>
                        <tbody >
                            {donationLinks.length > 0 ? (
                                donationLinks.map((link) => (
                                    <tr key={link._id}>
                                        <td className="border p-2 text-xs">{moment(link.createdAt).format('LL')}</td>
                                        <td className="border p-2 text-xs">{link.firstName}</td>
                                        <td className="border p-2 text-xs">{link.uniqueIdentifier}</td>
                                        <td className="border p-2 text-xs">{link.title}</td>
                                        <td className="border p-2 text-xs">{link.targetAmount}</td>
                                        <td className="border p-2 text-xs">{link.totalDonations}</td>
                                        <td className={`border p-2 text-xs ${getStatusColor(link.status)}`}>{link.status}</td>
                                        <td className="border p-2 text-center text-xs">
                                            <button
                                                onClick={() => navigate(`/single-donation-link-details/${link._id}`)}
                                                className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                            >
                                                View
                                            </button>
                                        </td>
                                        <td className="border p-2 text-center text-xs">
                                            <button
                                                onClick={() => navigate(`/user-details/${link.user}`)}
                                                className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                            >
                                                owner
                                            </button>
                                        </td>
                                        <td className="border p-2 text-center">
                                            <button
                                                onClick={() => navigate(`/edit-donation-links/${link._id}`)}
                                                className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                        <td className="border p-2 text-center">
                                            <button
                                                onClick={() => handleDeleteLinkClick(link)}
                                                className="px-4 py-2 text-white rounded text-xs bg-red-600 hover:bg-red-700"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                     
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="border p-2 text-center" colSpan="9">No donation links found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}

                <div className="flex justify-center space-x-2 mt-4">
                    {getPagination()}
                </div>
            </div>

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        <p className="my-4">Are you sure you want to delete the donation link "{linkToDelete?.title}"?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">Cancel</button>
                            <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DonationLinks;
