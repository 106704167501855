import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import successAnimation from "../../lottie/success-animation.json";
import successConfetti from '../../lottie/success-confetti.json';
import { FaSpinner } from 'react-icons/fa';
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import api from '../../../api';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';

const TransferImpactPoints = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [transferDetails, setTransferDetails] = useState({
        amount: '',
        payId: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [inputError, setInputError] = useState('');
    const [userError, setUserError] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTransferDetails({ ...transferDetails, [name]: value });
        setError('');
    };

    useEffect(() => {
        if (transferDetails.payId.length > 5) {
            if (transferDetails.payId === user?.payId) {
                setUserError("Cannot transact with your own account.");
                setReceiverName('Cannot transact with your own account.');
                setIsLoading(false);
            } else {
                setIsLoading(true);
                setReceiverName('...querying database for receiver name...');
                setUserError('');
                const fetchUserName = async () => {
                    try {
                        const response = await api.get(`/api/transfers/${transferDetails.payId}`, {
                            headers: { 'Authorization': `Bearer ${user.token}` },
                        });

                        if (response.status === 200 && response.data.name) {
                            setReceiverName(response.data.name);
                            setUserError('');
                        } else {
                            setReceiverName('Receiver Not found');
                            setUserError('Receiver Not found');
                        }
                    } catch (error) {
                        console.error("Error querying receiver name:", error);
                        setReceiverName('Receiver Not found');
                        setUserError('Receiver Not found');
                    } finally {
                        setIsLoading(false);
                    }
                };
                fetchUserName();
            }
        } else {
            setReceiverName('');
            setUserError('');
        }
    }, [transferDetails.payId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!transferDetails.amount || !transferDetails.payId) {
            setError('Please fill in all fields correctly.');
            return;
        }
        setIsSubmitting(true);
        setError('');

        try {
            const response = await api.post('/api/impacts/transfers', transferDetails, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (response.status === 201) {
                setSuccess(true);
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 10000);
            } else {
                setError("Failed to process transfer. Please try again.");
            }
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred during transfer.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg">
            {showConfetti && <Confetti />}

            {success ? (
                <div className="flex flex-col items-center justify-center w-full p-4">
                    <div className="relative w-full h-64 md:h-96">
                        <Lottie animationData={successConfetti} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                        <Lottie animationData={successAnimation} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                    </div>
                    <p className="text-lg font-semibold text-emerald-700 mt-4 text-center">Transfer Successful!</p>
                    <button
                        onClick={() => navigate('/dashboard')}
                        className="mt-4 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm bg-emerald-500 bg-opacity-10">
                        Done
                    </button>
                </div>
            ) : (
                <>
                    <div className="flex justify-center">
                        <img src="https://biznext.in/images/services/Money-Transfer-1.png" alt="Transfer Icon" className="w-24 h-auto mb-1" />
                    </div>
                    {error && (
                        <div className="mb-4 p-4 text-xs sm:text-xs bg-red-100 border-l-4 border-red-500 text-red-700">
                            <p>{error}</p>
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                        <div className="mb-2">
                            <label htmlFor="payId" className="block mb-1 text-sm font-medium text-gray-700">Pay ID</label>
                            <input
                                type="text"
                                name="payId"
                                value={transferDetails.payId}
                                onChange={handleChange}
                                className={`w-full text-xs sm:text-sm md:text-sm p-2 border rounded focus:outline-none ${userError ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'focus:ring-emerald-500 focus:border-emerald-500'}`}
                                placeholder="Enter receiver Pay ID"
                            />
                            {receiverName && <p className={`text-xs ${userError ? 'text-red-500' : 'text-green-600'}`}>{receiverName}</p>}
                        </div>

                        <div className="mb-2">
                            <label htmlFor="amount" className="block mb-1 text-sm font-medium text-gray-700">Amount</label>
                            <input
                                type="number"
                                name="amount"
                                value={transferDetails.amount}
                                onChange={handleChange}
                                className="w-full text-xs sm:text-sm p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                placeholder="Enter amount"
                            />
                        </div>

                        <div className="col-span-1">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`flex justify-center items-center w-full text-white py-2 px-4 rounded transition duration-300 ${isSubmitting ? 'bg-gray-400' : 'bg-emerald-500 hover:bg-emerald-600'}`}
                            >
                                {isSubmitting ? (
                                    <FaSpinner className="animate-spin mr-2" />
                                ) : (
                                    <HiOutlineChevronDoubleRight className="mr-2" />
                                )}
                                Transfer Points
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default TransferImpactPoints;
