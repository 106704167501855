import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiHeart } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';

const Hero = () => {
  const navigate = useNavigate(); 
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <section
    ref={ref}
    className="relative bg-cover bg-center bg-no-repeat sm:h-full"
    style={{ height: '70vh' }}
  >
    {/* Background video */}
    <video
      autoPlay
      muted
      loop
      playsInline
      className="absolute inset-0 object-cover w-full h-full"
    >
      <source src="/dashboard_images/Verdant_CHARITY.mp4" type="video/mp4" />
      {/* Fallback for older browsers */}
      Your browser does not support the video tag.
    </video>
  
    {/* Dark overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
  
    {/* Content */}
    <div className="relative h-full flex flex-col justify-center items-center text-white px-4 sm:px-6 lg:px-8">
      {/*
      <motion.h1
        variants={animationVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center"
        style={{ fontFamily: '"Dancing Script", cursive' }}
      >
        Making a Difference Together
      </motion.h1>
  
      <motion.p
        variants={animationVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center"
      >
        Join our community of donors, volunteers, and advocates to support those in need.
      </motion.p>
  
      {/* Call to Action Button */}
     <motion.div
  variants={animationVariants}
  initial="hidden"
  animate={inView ? "visible" : "hidden"}
  className="mt-6"
>
  <motion.button
    onClick={() => navigate('/dashboard')}
    className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded-full flex items-center justify-center"
    // Bounce effect
    animate={{ y: [0, -10, 0] }} // Move up by 10px and back down
    transition={{
      duration: 0.9,  // The duration of the bounce effect
      repeat: Infinity, // Repeat the bounce animation
      repeatType: "loop", // Continue bouncing in a loop
      ease: "easeInOut" // Smooth ease effect for bounce
    }}
    whileHover={{ scale: 1.1 }} // Slightly scale up on hover
    whileTap={{ scale: 0.9 }} // Slightly scale down on tap
  >
    <FiHeart className="mr-2" />
    Get donation Now
  </motion.button>
</motion.div>

    </div>
  </section>
  
  );
};

export default Hero;
