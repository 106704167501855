import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import loadingAnimation from '../../lottie/loading.json';
import noRecordAnimation from '../../lottie/noLinks.json';
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineDownload } from 'react-icons/hi';
import { useUser } from "../../context";
import jsPDF from "jspdf";

const DepositDetail = ({ depositId, onBack }) => {
    const { user } = useUser();
    const [depositDetails, setDepositDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        const fetchDepositDetails = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/deposits/deposits/${depositId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setDepositDetails(response.data);
                } else {
                    setError('Failed to fetch deposit details');
                }
            } catch (error) {
                console.error("Error fetching deposit details:", error);
                setError('An error occurred while fetching deposit details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchDepositDetails();
    }, [depositId, user.token]);

    const generateReceipt = async (depositDetails) => {
        setIsGenerating(true);
        const doc = new jsPDF();
    
        // Fetch images and convert to Base64 format
        const logoImage = await getImageBase64('https://res.cloudinary.com/dx6jw8k0m/image/upload/v1709472568/send-money__1_-removebg-preview_hg9tip.png');
        const barcodeImage = await getImageBase64('https://t3.ftcdn.net/jpg/02/55/97/94/360_F_255979498_vewTRAL5en9T0VBNQlaDBoXHlCvJzpDl.jpg');
    
        // Receipt Title and Subtitle
        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.setTextColor(34, 139, 34);
        doc.text("Verdant Charity Deposit Receipt", doc.internal.pageSize.getWidth() / 2, 20, { align: "center" });
    
        doc.setFont("helvetica", "italic");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text("Empowering Change, One Deposit at a Time", doc.internal.pageSize.getWidth() / 2, 30, { align: "center" });
    
        // Add logo image and a horizontal line
        doc.addImage(logoImage, 'PNG', doc.internal.pageSize.getWidth() / 2 - 20, 40, 40, 30);
        doc.setLineWidth(0.2);
        doc.line(20, 75, doc.internal.pageSize.getWidth() - 20, 75);
    
        // Personalized Greeting
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.setTextColor(0, 112, 186);
        doc.text(`Hi ${user?.primaryInfo?.firstName.toUpperCase()},`, doc.internal.pageSize.getWidth() / 2, 90, { align: "center" });
    
        // Square for Total Amount
        const squareX = doc.internal.pageSize.getWidth() / 2 - 60;
        const squareY = 100;
        doc.setDrawColor(0, 112, 186);
        doc.setFillColor(0, 112, 186);
        doc.rect(squareX, squareY, 40, 40, 'F');

        // Text inside the square
        doc.setFontSize(10);
        doc.setTextColor(255, 255, 255);

        const totalAmountText = "Total Amount:";
        const totalAmountTextWidth = doc.getStringUnitWidth(totalAmountText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const totalAmountTextX = squareX + (40 - totalAmountTextWidth) / 2; // Center the text in the square

        doc.text(totalAmountText, totalAmountTextX, squareY + 15);

        doc.setFontSize(14); // Set font size for the amount
        const amountText = `${depositDetails.currency} ${depositDetails.amount}`;
        const amountTextWidth = doc.getStringUnitWidth(amountText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const amountTextX = squareX + (40 - amountTextWidth) / 2; // Center the amount text in the square

        doc.text(amountText, amountTextX, squareY + 25);
        // Deposit Details
        const detailsX = squareX + 50;
        let detailsStartY = squareY;
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        doc.text(`Deposit ID: ${depositDetails.depositId}`, detailsX, detailsStartY);
        doc.text(`Initiator Phone: ${depositDetails.initiatorPhoneNumber}`, detailsX, detailsStartY += 10);
        doc.text(`Primary Phone: ${depositDetails.phoneNumber}`, detailsX, detailsStartY += 10);
        doc.text(`Transaction Date: ${new Date(depositDetails.transactionDate).toLocaleString()}`, detailsX, detailsStartY += 10);
        if (depositDetails.mpesaReceiptNumber) {
            doc.text(`M-Pesa Receipt: ${depositDetails.mpesaReceiptNumber}`, detailsX, detailsStartY += 10);
        }
        doc.text(`Status: ${depositDetails.isSuccess ? 'Completed' : 'Failed'}`, detailsX, detailsStartY += 10);
    
        // Barcode image
        doc.addImage(barcodeImage, 'PNG', doc.internal.pageSize.getWidth() / 2 - 80, detailsStartY + 20, 160, 30);
    
        // Footer
        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        const footerText = "If you encounter any issues, please contact support@verdantcharity.org. " +
            "Payment is secured with DLocal. Ravel Global Pay, Apt. 992, 54072 Larson Stravenue, Port Kymside, IA 70661-2925. " +
            "For support: support@verdantcharity.org | Hotline: +1 385 233 9891";
        doc.text(footerText, 20, doc.internal.pageSize.getHeight() - 30, { maxWidth: 180 });
    
        doc.save(`Deposit_Receipt_${depositDetails.depositId}.pdf`);
        setIsGenerating(false);
    };


    const getImageBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center">
                <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
            </div>
        );
    }

    if (!depositDetails || error) {
        return (
            <div className="flex flex-col justify-center items-center">
                <Lottie animationData={noRecordAnimation} style={{ width: 200, height: 200 }} />
                <p className="mt-4 text-sm font-semibold text-gray-600">{error || "No deposit details found."}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg">
            <div className="border-b border-dotted pb-4">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                <button onClick={onBack} className="flex items-center justify-center p-2 text-emerald-600 hover:text-emerald-800 transition-colors duration-150 ease-in-out mb-4 lg:mb-0 lg:mr-4">
                        <span className="inline-flex items-center justify-center p-2 mr-2 rounded-full border border-green-600 bg-green-100 hover:bg-green-200">
                            <AiOutlineArrowLeft />
                        </span>
                        Back
                    </button>
                    <p className="text-xs text-center text-gray-600 flex-1">Please review the details of your deposit below.</p>
                </div>
            </div>
            <div className="border p-4 rounded-lg">
    <div className="text-xs flex justify-between mb-2">
        <strong>Deposit ID:</strong>
        <span>{depositDetails.depositId}</span>
    </div>
    <div className="text-xs flex justify-between mb-2">
        <strong>Amount:</strong>
        <span>{depositDetails.amount} KES</span>
    </div>
    <div className="text-xs flex justify-between mb-2">
        <strong>Transaction Date:</strong>
        <span>{new Date(depositDetails.transactionDate).toLocaleString()}</span>
    </div>
    <div className="text-xs flex justify-between mb-2">
        <strong>Initiator Phone:</strong>
        <span>{depositDetails.initiatorPhoneNumber}</span>
    </div>
    <div className="text-xs flex justify-between mb-2">
        <strong>Primary Phone:</strong>
        <span>{depositDetails.phoneNumber}</span>
    </div>
    {depositDetails.mpesaReceiptNumber && (
        <div className="text-xs flex justify-between mb-2">
            <strong>M-Pesa Receipt:</strong>
            <span>{depositDetails.mpesaReceiptNumber}</span>
        </div>
    )}
    {depositDetails.error && (
        <div className="text-xs flex justify-between mb-2 text-red-500">
            <strong>Error:</strong>
            <span>{depositDetails.error}</span>
        </div>
    )}
 <div className="text-xs flex justify-between mb-2">
    <strong>Status:</strong>
    <span className={`px-2 rounded-full ${depositDetails.isSuccess ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
        {depositDetails.isSuccess ? 'Completed' : 'Failed'}
    </span>
</div>


    <div className="border-t border-dotted border-gray-400 pt-4 mt-4"></div> {/* Dotted border */}
    <button
                    className={`mt-4 px-4 py-2 flex justify-center items-center ${isGenerating ? 'bg-gray-500' : 'bg-blue-500'} text-white text-xs rounded hover:bg-blue-700 transition duration-150 ease-in-out w-full sm:w-auto`}
                    onClick={() => generateReceipt(depositDetails)}
                    disabled={isGenerating}
                >
                    {isGenerating ? (
                        <>
                            <AiOutlineLoading3Quarters className="animate-spin mr-2" /> Generating...
                        </>
                    ) : (
                        <>
                            <HiOutlineDownload className="mr-2" /> Download Receipt
                        </>
                    )}
                </button>
</div>

        </div>
    );
};

export default DepositDetail;
