import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './context';

const Navbar = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const externalLink = 'https://donations.verdantcharity.org';

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <nav className="bg-green-500 bg-opacity-30 backdrop-filter backdrop-blur-lg fixed top-0 left-0 w-full z-10 p-2 text-black">
      <div className="container mx-auto flex justify-between items-center">
      <div className="text-lg font-bold">
      <Link to="/" className="hover:text-white flex items-center">
  <img src="/dashboard_images/verdant_design.png" alt="Verdant Charity" className="h-12" />
</Link>
</div>

        {/* Links for larger screens */}
        <div className={`hidden md:flex space-x-4`}>
          <Link to="/" className="hover:text-white block px-3 py-2 rounded-md">Dashboard</Link>
          <Link to="/wallet" className="hover:text-white block px-3 py-2 rounded-md">Donate</Link>
          <Link to="/wallet" className="hover:text-white block px-3 py-2 rounded-md">Volunteer</Link>
          <Link to="/learn-more" className="hover:text-white block px-3 py-2 rounded-md">About</Link>
          <Link to="/learn-more/contact-us" className="hover:text-white block px-3 py-2 rounded-md">Contact-Us</Link>
          {user && (
            <button
              onClick={handleLogout}
              className="hover:text-white px-3 py-2 rounded-md text-sm bg-transparent border border-transparent hover:border-white"
            >
              Logout
            </button>
          )}
        </div>
        {/* Hamburger menu for smaller screens */}
        <div className="md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {/* Hamburger Icon */}
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden`}>
        <hr className="border-t border-gray-300 my-2" />
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link to="/" onClick={closeMobileMenu} className="hover:text-white block px-3 py-2 rounded-md">Dashboard</Link>
          <Link to="/wallet" onClick={closeMobileMenu} className="hover:text-white block px-3 py-2 rounded-md">Donate</Link>
          <Link to="/wallet" onClick={closeMobileMenu} className="hover:text-white block px-3 py-2 rounded-md">Volunteer</Link>
          <Link to="/learn-more" onClick={closeMobileMenu} className="hover:text-white block px-3 py-2 rounded-md">About</Link>
          <Link to="/learn-more/contact-us" onClick={closeMobileMenu} className="hover:text-white block px-3 py-2 rounded-md">Contact-Us</Link>
          {user && (
            <button
              onClick={() => {
                handleLogout();
                closeMobileMenu();
              }}
              className="hover:text-white px-3 py-2 rounded-md text-sm bg-transparent border border-transparent hover:border-white"
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
