import React, { useState } from 'react';
import WithdrawalHistory from './WithdrawalHistory';
import TradeHistory from './TradeHistory';
import TransferHistory from './TransferHistory';
import WithdrawalDetail from './WithdrawalDetail';
import TransferDetail from '../transactions/TransferDetail';
import DepositHistory from '../deposits/DepositHistory';
import DepositDetail from '../deposits/DepositDetail'; // Assuming you have a DepositDetail component
import ConvertHistory from '../conversion/ConvertHistory';
import ConversionDetail from '../conversion/ConversionDetail'; // Assuming you have a ConversionDetail component

const HistoryContainer = () => {
    const [activeTab, setActiveTab] = useState('withdrawHistory');
    const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);
    const [selectedTransferId, setSelectedTransferId] = useState(null);
    const [selectedDepositId, setSelectedDepositId] = useState(null);
    const [selectedConversionId, setSelectedConversionId] = useState(null);

    const handleViewDetails = (id, type) => {
        if (type === 'withdraw') {
            setSelectedWithdrawalId(id);
        } else if (type === 'transfer') {
            setSelectedTransferId(id);
        } else if (type === 'deposit') {
            setSelectedDepositId(id);
        } else if (type === 'conversion') {
            setSelectedConversionId(id);
        }
        setActiveTab('detail');
    };

    const handleBackToHistory = (type) => {
        if (type === 'withdraw') {
            setSelectedWithdrawalId(null);
        } else if (type === 'transfer') {
            setSelectedTransferId(null);
        } else if (type === 'deposit') {
            setSelectedDepositId(null);
        } else if (type === 'conversion') {
            setSelectedConversionId(null);
        }
        setActiveTab(`${type}History`);
    };

    const tabButtonClass = (tabName) => `w-full sm:w-auto py-2 px-4 border-b-2 font-medium text-sm sm:text-base ${
        activeTab === tabName ? 'border-emerald-500 text-emerald-600' : 'border-transparent hover:border-gray-300 hover:text-gray-600'
    }`;

    return (
        <>
            {selectedWithdrawalId && (
                <WithdrawalDetail withdrawalId={selectedWithdrawalId} onBack={() => handleBackToHistory('withdraw')} />
            )}
            {selectedTransferId && (
                <TransferDetail transferId={selectedTransferId} onBack={() => handleBackToHistory('transfer')} />
            )}
            {selectedDepositId && (
                <DepositDetail depositId={selectedDepositId} onBack={() => handleBackToHistory('deposit')} />
            )}
            {selectedConversionId && (
                <ConversionDetail conversionId={selectedConversionId} onBack={() => handleBackToHistory('conversion')} />
            )}

            {!selectedWithdrawalId && !selectedTransferId && !selectedDepositId && !selectedConversionId && (
                <div className="container flex-grow mx-auto p-4 bg-white rounded-lg shadow-md">
                    <div className="flex flex-wrap mb-4 border-b">
                        <button onClick={() => setActiveTab('withdrawHistory')} className={tabButtonClass('withdrawHistory')}>Withdrawal History</button>
                        <button onClick={() => setActiveTab('transferHistory')} className={tabButtonClass('transferHistory')}>Transfer History</button>
                        <button onClick={() => setActiveTab('tradeHistory')} className={tabButtonClass('tradeHistory')}>Trade History</button>
                        <button onClick={() => setActiveTab('depositHistory')} className={tabButtonClass('depositHistory')}>Deposit History</button>
                        <button onClick={() => setActiveTab('conversionHistory')} className={tabButtonClass('conversionHistory')}>Conversion History</button>
                    </div>
                    
                    {activeTab === 'withdrawHistory' && <WithdrawalHistory onViewDetails={(id) => handleViewDetails(id, 'withdraw')} />}
                    {activeTab === 'transferHistory' && <TransferHistory onViewDetails={(id) => handleViewDetails(id, 'transfer')} />}
                    {activeTab === 'depositHistory' && <DepositHistory onViewDetails={(id) => handleViewDetails(id, 'deposit')} />}
                    {activeTab === 'conversionHistory' && <ConvertHistory onViewDetails={(id) => handleViewDetails(id, 'conversion')} />}
                    {activeTab === 'tradeHistory' && <TradeHistory />}
                </div>
            )}
        </>
    );
};

export default HistoryContainer;
