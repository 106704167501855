import React, { useState, useEffect } from 'react';
import api from '../api';
import Lottie from "lottie-react";
import { useNavigate } from 'react-router-dom';
import successAnimation from "./lottie/success-animation.json";
import successConfetti from './lottie/success-confetti.json';
import { FaSpinner } from 'react-icons/fa';
import { useUser } from "./context";

// Function to get the locality (town or city) from coordinates using Google Maps API
const getLocationName = async (latitude, longitude) => {
    try {
        const apiKey = 'AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE'; // Replace with your Google Maps API key
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
        const data = await response.json();

        if (data.status === "OK") {
            const locality = data.results.find(result =>
                result.types.includes("locality") ||
                result.types.includes("administrative_area_level_2")
            );
            return locality ? locality.formatted_address : data.results[0]?.formatted_address || 'Unknown Location';
        }
        return 'Unknown Location';
    } catch (error) {
        console.error("Error getting location name:", error);
        return 'Unknown Location';
    }
};

const Kyc = () => {
    const navigate = useNavigate();
    const { user, login } = useUser();
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isExact18YearsAgo, setIsExact18YearsAgo] = useState(false);
    const [locationPermissionStatus, setLocationPermissionStatus] = useState(''); // To track location permission
    const [showModal, setShowModal] = useState(false); // Modal for location permission
    const [kycDetails, setKycDetails] = useState({
        firstName: '',
        lastName: '',
        phone: user?.phoneNumber || '',
        email: user?.email || '',
        town: '',
        dob: '',
        idNumber: '',
        ip: '',
        city: '',
        region: '',
        countryCode: '',
        timezone: '',
        location: '' // Stores latitude, longitude
    });


    const calculateMaxDob = () => {
        const today = new Date();
        const maxYear = today.getFullYear() - 18;
        const maxMonth = String(today.getMonth() + 1).padStart(2, '0');
        const maxDay = String(today.getDate()).padStart(2, '0');
        return `${maxYear}-${maxMonth}-${maxDay}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKycDetails({ ...kycDetails, [name]: value });

        if (name === 'dob') {
            const maxDob = calculateMaxDob();
            setIsExact18YearsAgo(value === maxDob);
        }
    };


    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        } else {
            // Fetch IP and location info from external API
            fetch("https://ipinfo.io/json?token=bff95b04d027fd")
                .then(response => response.json())
                .then(data => {
                    setKycDetails((prevDetails) => ({
                        ...prevDetails,
                        ip: data.ip,
                        city: data.city,
                        region: data.region,
                        countryCode: data.country,
                        timezone: data.timezone
                    }));
                })
                .catch(err => {
                    setError("Failed to get location information.");
                    console.error("Error fetching location:", err);
                });
        }
    }, [user]);

    // Check and request location permission
    useEffect(() => {
        const checkLocationPermission = async () => {
            try {
                if (navigator.permissions && navigator.permissions.query) {
                    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                    setLocationPermissionStatus(permissionStatus.state);

                    if (permissionStatus.state === 'granted') {
                        getUserLocation(); // Fetch location if permission is granted
                    } else if (permissionStatus.state === 'prompt') {
                        setShowModal(true); // Show modal for location permission prompt
                    } else if (permissionStatus.state === 'denied') {
                        handleDeniedLocation();
                    }
                } else {
                    // Fallback for older browsers without navigator.permissions
                    getUserLocation();
                }
            } catch (err) {
                console.error('Error checking location permission:', err);
            }
        };

        checkLocationPermission();
    }, []);

    // Function to get the user's location
    const getUserLocation = () => {
        if (!navigator.geolocation) {
            setError("Geolocation is not supported by your browser.");
        } else {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setKycDetails((prevDetails) => ({
                        ...prevDetails,
                        location: `${latitude},${longitude}`
                    }));
                    try {
                        const locationName = await getLocationName(latitude, longitude);
                        setKycDetails((prevDetails) => ({
                            ...prevDetails,
                            town: locationName
                        }));
                    } catch (error) {
                        setError("Failed to fetch location name reload the page.");
                    }
                    setShowModal(false); // Hide modal after permission is granted
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        handleDeniedLocation();
                    } else {
                        setError("Failed to get location reload the page.");
                    }
                    setShowModal(false);
                },
                { timeout: 10000 }
            );
        }
    };

    // Handle denied location permissions
    const handleDeniedLocation = () => {
        setError(
            <>
                <p>Location access was denied. To re-enable it, please follow these steps for Chrome Browser:</p>
                <ol className="list-decimal ml-4">
                    <li>Click the three vertical dots on the top-right corner of the browser.</li>
                    <li>Select "Settings", then navigate to "Privacy and security" or "Site settings".</li>
                    <li>Find "Location", and under "Blocked sites", change the setting to "Allow" or remove the site from the block list.</li>
                    <li>Refresh the page and try again.</li>
                </ol>
            </>
        );
    };

    const requestLocationPermission = () => {
        getUserLocation(); // Reuse getUserLocation for requesting location permission
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);
        setIsSuccessful(false);

        const userToken = user?.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        };

        try {
            const response = await api.post('/api/auth/submit-kyc', kycDetails, config);
            if (response.status === 201 || response.status === 200) {
                login({ ...user, primaryInfo: response.data.primaryInfo });
                setIsSuccessful(true);
            } else {
                setError('Unexpected response from the server. Please try again.');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to submit KYC data. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container mx-auto my-10 p-2 sm:p-4 bg-white rounded-lg shadow-lg text-xs border border-gray-300 sm:mx-2 lg:mx-auto">
            <h2 className="text-lg font-bold mb-4 text-center border-b-2 border-dotted border-gray-400 pb-2">
                KYC Verification
            </h2>

            {error && (
                <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 text-xs">
                    <p>{error}</p>
                </div>
            )}

            {!isSuccessful ? (
                <>
                    <p className="text-sm text-gray-500 mb-4 text-xs">
                        Please fill in the information below to complete your KYC verification.
                    </p>
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {/* First Name */}
                        <div className="mb-2">
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 text-xs">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                value={kycDetails.firstName}
                                onChange={handleChange}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                placeholder="Enter first name"
                            />
                        </div>

                        {/* Last Name */}
                        <div className="mb-2">
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 text-xs">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                value={kycDetails.lastName}
                                onChange={handleChange}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                placeholder="Enter last name"
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="dob" className="block text-sm font-medium text-gray-700 text-xs">Date of Birth</label>
                            <input
                                type="date"
                                name="dob"
                                value={kycDetails.dob}
                                onChange={handleChange}
                                max={calculateMaxDob()} // Set the max attribute dynamically
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                            />
                            {isExact18YearsAgo && <p className="text-xs text-blue-600 mt-2">Are you sure that is your date of birth?</p>}
                        </div>


                        {/* ID Number */}
                        <div className="mb-2">
                            <label htmlFor="idNumber" className="block text-sm font-medium text-gray-700 text-xs">ID Number</label>
                            <input
                                type="text"
                                name="idNumber"
                                value={kycDetails.idNumber}
                                onChange={handleChange}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                placeholder="Enter ID number"
                            />
                        </div>

                        {/* Town */}
                        <div className="mb-2">
                            <label htmlFor="town" className="block text-sm font-medium text-gray-700 text-xs">Town</label>
                            <input
                                type="text"
                                name="town"
                                value={kycDetails.town}
                                onChange={handleChange}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                placeholder="Nearest Town"
                                readOnly
                            />
                        </div>

                        {/* Phone (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 text-xs">
                                Phone
                            </label>
                            <input
                                type="text"
                                name="phone"
                                value={kycDetails.phone}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                placeholder="Phone number"
                                readOnly
                            />
                        </div>

                        {/* Email (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-xs">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={kycDetails.email}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                placeholder="Email address"
                                readOnly
                            />
                        </div>

                        {/* City (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700 text-xs">
                                City
                            </label>
                            <input
                                type="text"
                                name="city"
                                value={kycDetails.city}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                readOnly
                            />
                        </div>

                        {/* Region (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700 text-xs">
                                Region
                            </label>
                            <input
                                type="text"
                                name="region"
                                value={kycDetails.region}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                readOnly
                            />
                        </div>

                        {/* Country Code (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700 text-xs">
                                Country Code
                            </label>
                            <input
                                type="text"
                                name="countryCode"
                                value={kycDetails.countryCode}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                readOnly
                            />
                        </div>

                        {/* Timezone (Read-only) */}
                        <div className="mb-2">
                            <label htmlFor="timezone" className="block text-sm font-medium text-gray-700 text-xs">
                                Timezone
                            </label>
                            <input
                                type="text"
                                name="timezone"
                                value={kycDetails.timezone}
                                className="w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 bg-gray-100"
                                readOnly
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="col-span-1 md:col-span-2">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`w-full border py-2 px-4 rounded transition duration-300 ${isSubmitting ? 'bg-emerald-500 text-white' : 'border-emerald-500 text-emerald-500 hover:bg-emerald-500 hover:text-white'
                                    }`}
                            >
                                {isSubmitting ? (
                                    <>
                                        <FaSpinner className="animate-spin inline mr-2" />
                                        Submitting...
                                    </>
                                ) : (
                                    'Submit KYC'
                                )}
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <div className="flex flex-col items-center justify-center w-full p-4">
                    {/* Success Animations */}
                    <div className="relative w-full h-64 md:h-96">
                        <Lottie animationData={successConfetti} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                        <Lottie animationData={successAnimation} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                    </div>
                    <p className="text-lg font-semibold text-emerald-700 mt-4 text-center">
                        KYC Submitted Successfully!
                    </p>
                    <button
                        onClick={() => navigate('/wallet')}
                        className="mt-4 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm bg-emerald-500 bg-opacity-10"
                    >
                        Done
                    </button>
                </div>
            )}

            {/* Modal for Location Permission */}
            {showModal && (
                <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
                        <h2 className="text-lg font-bold mb-4">Location Access Needed</h2>

                        <p className="text-gray-600 mb-4">
                            Verdant charity needs access to your location to ensure the security and authenticity of your account. This is essential for a financial institution.
                        </p>

                        {/* Information Box */}
                        <div className="bg-gray-100 p-4 border border-gray-300 rounded-lg flex items-start text-left mb-4">
                            <span className="text-blue-500 mr-3 text-xl">ℹ️</span> {/* Informational Icon */}
                            <p className="text-gray-600 text-xs">
                                In cases where you deny the permission, you will not be able to proceed. Location is part of what we record as a responsible financial institution to ensure compliance and security.
                            </p>
                        </div>

                        <button
                            onClick={requestLocationPermission}
                            className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 transition duration-300"
                        >
                            Grant Location Access
                        </button>
                    </div>
                </div>
            )}


        </div>
    );
};

export default Kyc;
