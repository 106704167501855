import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow, differenceInHours } from 'date-fns';

const PendingWithdrawals = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [withdrawalToApprove, setWithdrawalToApprove] = useState(null);
    const [withdrawalToDelete, setWithdrawalToDelete] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const fetchPendingWithdrawals = async () => {
            try {
                const response = await api.get(`/api/customer/pending-withdrawals`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setPendingWithdrawals(response.data);
                } else {
                    toast.error('Failed to load pending withdrawal data');
                }
            } catch (error) {
                console.error("Error fetching pending withdrawal data: ", error);
                toast.error(`Failed to load pending withdrawal data: ${error.message}`);
            }
        };

        fetchPendingWithdrawals();
    }, [user.token]);

    const handleApprove = async (withdrawal) => {
        setIsProcessing(true);
        try {
            const response = await api.post('/api/admin/withdrawals/approve', { withdrawalId: withdrawal._id }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (response.status === 200) {
                toast.success('Withdrawal approved successfully');
                setPendingWithdrawals(pendingWithdrawals.filter(w => w._id !== withdrawal._id));
            } else {
                toast.error(`Failed to approve withdrawal: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error approving withdrawal: ", error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(`Failed to approve withdrawal: ${errorMessage}`);
        } finally {
            setIsProcessing(false);
            setShowApproveModal(false);
        }
    };

    const handleDelete = async (withdrawal) => {
        setIsProcessing(true);
        try {
            const response = await api.delete(`/api/admin/withdrawals/${withdrawal._id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (response.status === 200) {
                toast.success('Withdrawal deleted successfully');
                setPendingWithdrawals(pendingWithdrawals.filter(w => w._id !== withdrawal._id));
            } else {
                toast.error(`Failed to delete withdrawal: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error deleting withdrawal: ", error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(`Failed to delete withdrawal: ${errorMessage}`);
        } finally {
            setIsProcessing(false);
            setShowDeleteModal(false);
        }
    };

    const calculateHoursSince = (date) => {
        const now = new Date();
        const createdAt = new Date(date);
        const hours = differenceInHours(now, createdAt);
        return `${hours} Hrs`;
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <ToastContainer />
            <h3 className="font-semibold text-lg mb-4">Pending Withdrawals</h3>
            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">Withdrawal ID</th>
                            <th className="px-4 py-2">Name</th>
                            <th className="px-4 py-2">Amount</th>
                            <th className="px-4 py-2">Currency</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Date</th>
                            <th className="px-4 py-2">View More</th>
                            <th className="px-4 py-2">Approve</th>
                            <th className="px-4 py-2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingWithdrawals.map((withdrawal) => (
                            <tr key={withdrawal._id} className="border-b">
                                <td className="px-4 py-2">{withdrawal.withdrawalId}</td>
                                <td className="px-4 py-2">{withdrawal.firstName}</td>
                                <td className="px-4 py-2">{withdrawal.amount}</td>
                                <td className="px-4 py-2">{withdrawal.currency}</td>
                                <td className="px-4 py-2">{withdrawal.status}</td>
                                <td className="px-4 py-2">
                                    {calculateHoursSince(withdrawal.createdAt)}
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => navigate(`/user-details/${withdrawal.userId}`)}
                                        className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                    >
                                        View More
                                    </button>
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => {
                                            setWithdrawalToApprove(withdrawal);
                                            setShowApproveModal(true);
                                        }}
                                        className="px-4 py-2 border border-green-300 rounded text-xs text-green-700 hover:bg-green-100"
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? 'Approving...' : 'Approve'}
                                    </button>
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => {
                                            setWithdrawalToDelete(withdrawal);
                                            setShowDeleteModal(true);
                                        }}
                                        className="px-4 py-2 border border-red-300 rounded text-xs text-red-700 hover:bg-red-100"
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? 'Deleting...' : 'Delete'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showApproveModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <div className="border-b pb-2 mb-2">
                            <h2 className="text-lg font-semibold">Confirm Approval</h2>
                        </div>
                        <p className="mb-4">Are you sure you want to approve the withdrawal of <strong>{withdrawalToApprove.amount}</strong> for <strong>{withdrawalToApprove.firstName}</strong>?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setShowApproveModal(false)} className="px-4 py-2 border border-gray-300 text-gray-800 rounded hover:bg-gray-100" disabled={isProcessing}>Cancel</button>
                            <button onClick={() => handleApprove(withdrawalToApprove)} className="px-4 py-2 border border-green-300 text-green-700 rounded hover:bg-green-100" disabled={isProcessing}>
                                {isProcessing ? 'Approving...' : 'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <div className="border-b pb-2 mb-2">
                            <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        </div>
                        <p className="mb-4">Are you sure you want to delete the withdrawal of <strong>{withdrawalToDelete.amount}</strong> for <strong>{withdrawalToDelete.firstName}</strong>?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 border border-gray-300 text-gray-800 rounded hover:bg-gray-100" disabled={isProcessing}>Cancel</button>
                            <button onClick={() => handleDelete(withdrawalToDelete)} className="px-4 py-2 border border-red-300 text-red-700 rounded hover:bg-red-100" disabled={isProcessing}>
                                {isProcessing ? 'Deleting...' : 'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PendingWithdrawals;
