import { useState, useEffect } from 'react';
import api from '../../api';
import { useUser } from '../context';
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineInfoCircle, AiOutlineMail, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineLock } from 'react-icons/ai'; // Importing icons
import { FaSpinner } from 'react-icons/fa';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Function to get the user's fingerprint
const getFingerprint = async () => {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        return result.visitorId;
    } catch (error) {
        console.error("Error obtaining fingerprint:", error);
        return null;
    }
};

// Function to get the locality (town or city) from coordinates using Google Maps API
const getLocationName = async (latitude, longitude) => {
    try {
        const apiKey = 'AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE'; // Replace with your Google Maps API key
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
        const data = await response.json();

        if (data.status === "OK") {
            // Loop through the results and find the locality
            const locality = data.results.find(result =>
                result.types.includes("locality") ||
                result.types.includes("administrative_area_level_2") // Often used for towns/cities
            );

            // If locality is found, return it; otherwise, return formatted address or unknown
            return locality
                ? locality.formatted_address
                : data.results[0]?.formatted_address || 'Unknown Location';
        }

        return 'Unknown Location';
    } catch (error) {
        console.error("Error getting location name:", error);
        return 'Unknown Location';
    }
};


const Login = () => {
    const { login, logout } = useUser();
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null); // Location coordinates
    const [locationName, setLocationName] = useState(''); // Location name
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordVisibility = () => setShowPassword(!showPassword);
    const [locationPermissionStatus, setLocationPermissionStatus] = useState('');

    useEffect(() => {
        logout(); // Log the user out on component mount
    }, [logout]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLoginData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const checkLocationPermission = async () => {
            try {
                if (navigator.permissions && navigator.permissions.query) {
                    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                    setLocationPermissionStatus(permissionStatus.state);
                    
                    if (permissionStatus.state === 'granted') {
                        // Permission granted, get location immediately
                        getUserLocation();
                    } else if (permissionStatus.state === 'prompt') {
                        // Show the modal to ask for permission
                        setShowModal(true);
                    } else if (permissionStatus.state === 'denied') {
                        handleDeniedLocation();
                    }
                } else {
                    // Fallback: Directly request geolocation for older browsers
                    getUserLocation();
                }
            } catch (err) {
                console.error('Error checking location permission:', err);
            }
        };
    
        checkLocationPermission();
    }, []);
    
    // Function to get the user's location
    const getUserLocation = () => {
        if (!navigator.geolocation) {
            setError("Geolocation is not supported by your browser.");
        } else {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ latitude, longitude });
    
                    try {
                        const locationName = await getLocationName(latitude, longitude);
                        setLocationName(locationName);  // Await response to avoid setting 'Unknown Location'
                    } catch (error) {
                        console.error("Failed to get location name:", error);
                        setLocationName("Unknown Location");  // Fallback
                    }
    
                    setShowModal(false);  // Close modal after success
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        handleDeniedLocation();
                    } else {
                        setError("Failed to get location reload the page.");
                    }
                    setShowModal(false);  // Close modal on failure
                },
                { timeout: 10000 }  // Timeout after 10 seconds
            );
        }
    };
    
    // Handle denied location permissions
    const handleDeniedLocation = () => {
        setError(
            <>
                <p>Location access was denied. To re-enable it, please follow these steps for Chrome Browser:</p>
                <ol className="list-decimal ml-4">
                    <li>Click the three vertical dots on the top-right corner of the browser.</li>
                    <li>Select "Settings", then navigate to "Privacy and security" or "Site settings".</li>
                    <li>Find "Location", and under "Blocked sites", change the setting to "Allow" or remove the site from the block list.</li>
                    <li>Refresh the page and try again.</li>
                </ol>
            </>
        );
    };
    
    // Request location permission via modal
    const requestLocationPermission = () => {
        getUserLocation();  // Reuse getUserLocation to avoid duplication
    };
    
    
    // Modified handleLogin function to include location data
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const fingerprintId = await getFingerprint();

            // Include location data if available
            const loginPayload = {
                ...loginData,
                fingerprintId,
                location: location ? { latitude: location.latitude, longitude: location.longitude } : null,
                locationName: locationName || null
            };

            const response = await api.post('/api/auth/login', loginPayload);

            if (response.status === 200) {
                const user = response.data;
                login(user);
                navigate('/dashboard');
            } else {
                setError('Login failed due to unexpected response. Please try again later.');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                if (error.response.data.message.includes('Phone verification needed')) {
                    const { token, phoneNumber } = error.response.data;
                    navigate('/phone-verify', { state: { phoneNumber, token } });
                } else {
                    navigate('/verify', { state: { email: loginData.email, token: error.response.data.token } });
                }
            } else {
                setError(error.response?.data?.message || 'Login failed. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="min-h-screen w-full flex items-center justify-center bg-cover bg-center"
            style={{
                backgroundImage: "url('/auth_images/auth_background.jpg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                minHeight: '100vh',
                backgroundAttachment: 'fixed'
            }}>
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center overflow-auto"> {/* Changed overflow-hidden to overflow-auto */}
                <div className="relative z-10 p-8 rounded-lg max-w-md w-full space-y-8 bg-black bg-opacity-10 text-center">


                    {/* Message about viewing sub-portal */}
                    <div className="bg-gray-200 border border-gray-400 text-gray-700 px-4 py-3 rounded relative mb-4 text-center">
                        <AiOutlineInfoCircle className="inline-block text-emerald-500 mr-2" size="1.25em" />
                        <span className="text-xs sm:text-sm">You are now viewing a wallet-portal of Verdant Charity. To learn more about us, </span>
                        <a href="https://donations.verdantcharity.org/learn-more" className="font-medium text-emerald-600 hover:text-emerald-500 underline ml-1 text-xs sm:text-sm">click here</a>.
                    </div>
                    {/* Centered small image */}
                    <div className="flex justify-center">
                        <img src="https://cdn-icons-png.flaticon.com/512/8910/8910788.png" alt="Login Icon" className="w-24 h-auto mb-1" /> {/* Adjust the w-24 class as needed for your image size */}
                    </div>
                    <div className="text-center">
                        <h2 className="text-xs sm:text-2xl md:text-3xl font-bold text-emerald-600 mb-2">Sign in </h2>
                    </div>

                    <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Error!</strong>
                                <span className="block sm:inline text-xs"> {error}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                    <svg className="fill-current h-6 w-6 text-red-500" role="img" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 0 1 1.697 0c.461.486.461 1.211 0 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                                </span>
                            </div>
                        )}

                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className="mb-4 relative">
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <AiOutlineMail className="absolute top-3 left-3 text-emerald-500" size="1.25em" />
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="text-xs sm:text-sm block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    placeholder="Email address"
                                    value={loginData.email}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-4 relative">
                                <label htmlFor="password" className="sr-only">Password</label>
                                <AiOutlineLock className="absolute top-3 left-3 text-emerald-500" size="1.25em" />
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    className="text-xs sm:text-sm block w-full pl-10 pr-12 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    placeholder="Password"
                                    value={loginData.password}
                                    onChange={handleInputChange}
                                />
                                <button
                                    type="button"
                                    onClick={handlePasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-xs sm:text-sm">
                                    {showPassword ? (
                                        <AiOutlineEyeInvisible className="text-emerald-500" size="1.25em" />
                                    ) : (
                                        <AiOutlineEye className="text-emerald-500" size="1.25em" />
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded" />
                                <label htmlFor="remember-me" className="ml-2 block text-xs sm:text-sm text-gray-900">Remember me</label>
                            </div>

                            <div className="text-sm">
                                <Link to="/forgot-password" className="font-medium text-emerald-600 hover:text-emerald-500 text-xs sm:text-sm">Forgot your password?</Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white ${locationPermissionStatus === 'denied' ? 'bg-gray-400 cursor-not-allowed' : 'bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-500'}`}
                                disabled={locationPermissionStatus === 'denied'}
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    {loading ? <FaSpinner className="h-5 w-5 text-white animate-spin" /> : null}
                                </span>
                                {loading ? 'Signing in...' : 'Sign in'}
                            </button>
                        </div>

                        {/* Show error if location permission is denied */}
                        {locationPermissionStatus === 'denied' && (
                            <p className="text-xs text-red-500 mt-2">Please enable location services to proceed with login.</p>
                        )}

                        <div className="text-center">
                            <span className="text-xs sm:text-sm text-white">Don't have an account?</span>
                            <Link to="/signup" className="font-medium text-emerald-600 hover:text-emerald-500 underline ml-1 text-xs sm:text-sm">Create new account</Link>
                        </div>
                    </form>
                </div>
            </div>
            {/* Modal for Location Permission */}
            {showModal && (
                <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
                        <h2 className="text-lg font-bold mb-4">Location Access Needed</h2>

                        <p className="text-gray-600 text-xs mb-4">
                            Verdant charity needs access to your location to ensure the security and authenticity of your account. This is essential for a financial institution.
                        </p>

                        {/* Information Box */}
                        <div className="bg-gray-100 p-4 border border-gray-300 rounded-lg flex items-start text-left mb-4">
                            <span className="text-blue-500 mr-3 text-xl">ℹ️</span> {/* Informational Icon */}
                            <p className="text-gray-600 text-xs">
                                In cases where you deny the permission, you will not be able to proceed. Location is part of what we record as a responsible financial institution to ensure compliance and security.
                            </p>
                        </div>

                        <button
                            onClick={requestLocationPermission}
                            className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 transition duration-300"
                        >
                            Grant Location Access
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;
