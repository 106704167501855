// AccessHistory.js

import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import unavailableAnimation from '../../lottie/noLinks.json';
import loadingAnimation from '../../lottie/loading.json';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';
import { FaDesktop, FaMobileAlt, FaTabletAlt, FaMapMarkerAlt } from 'react-icons/fa';

const AccessHistory = ({ onViewDetails }) => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [loginHistory, setLoginHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        }
    }, [user, navigate]);

    useEffect(() => {
        const fetchLoginHistory = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/auth/recent-login-history', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    }
                });
                setLoginHistory(response.data);
            } catch (error) {
                console.error("Error fetching login history: ", error);
            }
            setIsLoading(false);
        };

        fetchLoginHistory();
    }, [user.token]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const getDeviceIcon = (device) => {
        switch (device.toLowerCase()) {
            case 'desktop':
                return <FaDesktop className="mr-1 text-blue-500" />;
            case 'mobile':
                return <FaMobileAlt className="mr-1 text-green-500" />;
            case 'tablet':
                return <FaTabletAlt className="mr-1 text-purple-500" />;
            default:
                return <FaDesktop className="mr-1 text-gray-500" />;
        }
    };

    return (
        <div className="flex flex-col flex-grow container mx-auto p-4 bg-white rounded-lg text-center">
            {isLoading ? (
                <div className="flex justify-center items-center h-64"> {/* Centered the loading icon */}
                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                </div>
            ) : loginHistory.length > 0 ? (
                loginHistory.map((login, index) => (
                    <div key={index} className="flex items-center justify-between p-2 mt-3 bg-green-50 border rounded">
                        <div className="text-left">
                        <p className="font-semibold text-xs flex items-center">
    <FaMapMarkerAlt className="mr-1 text-red-500" />  {/* Location icon */}
    {login.locationName ? 
        (login.locationName.length > 30 ? `${login.locationName.substring(0, 18)}...` : login.locationName) 
        : 'Unknown'}
</p>

                            <p className="text-xs text-gray-500"> {formatDate(login.loginTime)}</p>
                            <p className="flex items-center text-xs text-gray-500">
                                {getDeviceIcon(login.device)} {login.device}
                            </p>
                        </div>
                        <button
                            onClick={() => onViewDetails(login._id)}  // Pass the login ID to view details
                            className="px-4 py-1 text-xs border border-emerald-500 text-emerald-500 rounded hover:bg-emerald-100 hover:text-emerald-600">
                            View
                        </button>
                    </div>
                ))
            ) : (
                <div className="flex flex-col items-center justify-center h-64"> {/* Centered no records found text */}
                    <Lottie animationData={unavailableAnimation} style={{ width: 200, height: 200 }} />
                    <p className="text-gray-500 font-semibold mt-4">No Login History Found</p>
                </div>
            )}
        </div>
    );
};

export default AccessHistory;
