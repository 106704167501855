import React, { useState, useEffect } from 'react';
import Kyc from './Kyc';
import { FaCheckCircle, FaMapMarkerAlt, FaCrown, FaBusinessTime, FaUser } from 'react-icons/fa';
import { useUser } from "./context";
import EditProfile from './EditProfile';
import { AiOutlineCheck, AiFillPhone, AiOutlineMail, AiFillCalendar, AiFillIdcard, AiFillHome, AiFillFlag, AiFillEdit } from 'react-icons/ai';
import api from '../api';

const Profile = () => {
    const { user } = useUser();
    const [editMode, setEditMode] = useState(false);
    const [mostRecentLocation, setMostRecentLocation] = useState('');
    const [loginHistory, setLoginHistory] = useState([]);
    const [coordinates, setCoordinates] = useState([36.8167, -1.2833]); // [longitude, latitude] for Nairobi

    useEffect(() => {
        const fetchRecentLoginHistory = async () => {
            try {
                const response = await api.get('/api/auth/recent-login-history', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user?.token}` // Pass the token for authorization
                    }
                });

                const history = response.data;
                if (history.length > 0) {
                    // Get most recent location and coordinates in the correct order (longitude, latitude)
                    const { locationName, location } = history[0];
                    setMostRecentLocation(locationName || 'Location unavailable');
                    setCoordinates(location.coordinates); // Coordinates from the database are in the correct order
                    setLoginHistory(history);
                }
            } catch (error) {
                console.error("Failed to fetch login history:", error);
            }
        };

        if (user?.token) {
            fetchRecentLoginHistory();
        }
    }, [user]);

    // Map URL with correct order of lat-long (Google Maps expects lat-long order)
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE&q=${coordinates[1]},${coordinates[0]}&zoom=14&maptype=roadmap`;


    const information = [
        { label: 'First Name', value: user?.primaryInfo?.firstName, icon: <AiFillIdcard /> },
        { label: 'Last Name', value: user?.primaryInfo?.lastName, icon: <AiFillIdcard /> },
        { label: 'Phone', value: user?.primaryInfo?.phone, icon: <AiFillPhone /> },
        { label: 'Email', value: user?.primaryInfo?.email, icon: <AiOutlineMail /> },
        { label: 'Date of Birth', value: user?.primaryInfo?.dob ? new Date(user.primaryInfo.dob).toLocaleDateString() : "", icon: <AiFillCalendar /> },
        { label: 'ID Number', value: user?.primaryInfo?.idNumber, icon: <AiFillIdcard /> },
        { label: 'Town', value: user?.primaryInfo?.town, icon: <AiFillHome /> },
        { label: 'Country', value: user?.primaryInfo?.country, icon: <AiFillFlag /> },

        // New fields from the KYC schema
        { label: 'City', value: user?.primaryInfo?.city || 'Nairobi', icon: <AiFillHome /> },
        { label: 'Region', value: user?.primaryInfo?.region || 'Nairobi County', icon: <AiFillFlag /> },
        { label: 'Country Code', value: user?.primaryInfo?.countryCode || 'KE', icon: <AiFillFlag /> },
        { label: 'Timezone', value: user?.primaryInfo?.timezone || 'Africa/Nairobi', icon: <AiFillCalendar /> },

    ];


    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleSaveChanges = (information) => {
        console.log(information);
        setEditMode(false);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
    };

    const isKycAvailable = user?.primaryInfo?.firstName && user?.primaryInfo?.lastName;

    if (!isKycAvailable) {
        return <Kyc />;
    } else if (editMode) {
        return (
            <EditProfile
                initialData={user?.primaryInfo}
                onSave={handleSaveChanges}
                onCancel={handleCancelEdit}
            />
        );
    } else {
        return (
            <div className="container flex-grow bg-white rounded-md mx-auto">
                <div className="pt-4">
                    <div className="bg-white overflow-hidden sm:rounded-lg">
                        {/* Define the grid with 3 columns on medium screens and up */}
                        <div className="relative px-2 py-3 sm:px-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        
                            {/* Business or Individual Badge */}
                            <div className="absolute top-0 right-0 mt-4 mr-4">
                                <span
                                    className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${user?.accountType === 'business'
                                        ? 'bg-blue-100 text-blue-800'
                                        : 'bg-green-100 text-green-800'
                                        }`}
                                >
                                    {user?.accountType === 'business' ? (
                                        <>
                                            <FaBusinessTime className="mr-1 text-xs" /> Business
                                        </>
                                    ) : (
                                        <>
                                            <FaUser className="mr-1 text-xs" /> Individual
                                        </>
                                    )}
                                </span>
                            </div>
        
                            {/* Map Grid (1/3 of the width) */}
                            <div className="map-grid col-span-1 flex flex-col items-center p-2 text-center border border-gray">
                                <img
                                    src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRkq5kkpQ3rL0fQy182fLw3qpZ199C7cHY4nvl59iOyt24q0hDc"
                                    alt="Profile"
                                    className="h-20 w-20 rounded-full"
                                />
                                <div className="mt-3 w-full">
                                    <h3 className="text-sm leading-6 font-medium text-gray-900 flex items-center justify-center truncate w-full">
                                        {user?.primaryInfo?.firstName + " " + user?.primaryInfo?.lastName}
                                        {user?.isPremium && <FaCheckCircle className="ml-1 text-blue-500" />}
                                    </h3>
                                    <span
                                        className={`text-xs rounded-full px-2 py-1 mt-1 inline-flex items-center ${user?.isBanned
                                            ? 'text-white bg-red-600'
                                            : user?.isVerified && user?.isPremium
                                                ? 'text-yellow-500 bg-blue-700'
                                                : user?.isVerified
                                                    ? 'text-blue-700 bg-blue-100'
                                                    : 'text-green-700 bg-green-100'
                                            }`}
                                    >
                                        {user?.isBanned
                                            ? "Banned"
                                            : user?.isVerified && user?.isPremium
                                                ? <><FaCrown className="mr-1" /> Premium</>
                                                : user?.isVerified
                                                    ? "Active"
                                                    : <><AiOutlineCheck className="mr-1" /> Verified</>}
                                    </span>
        
                                    {/* Joined Date */}
                                    <div className="text-xs text-gray-500 mt-1 text-center">{`Joined: ${new Date(user?.primaryInfo?.createdAt).toLocaleDateString()}`}</div>
        
                                    {/* Pulsing Location Icon */}
                                    <div className="flex items-center justify-center text-xs text-gray-500 mt-1">
                                        <FaMapMarkerAlt className="animate-pulse text-red-500 mr-1" />
                                        {mostRecentLocation}
                                    </div>
        
                                    {/* Embed Map */}
                                    <div className="mt-4 border border-gray-300 rounded-lg overflow-hidden">
                                        <iframe
                                            className="w-full h-64 sm:h-80 md:h-96"
                                            frameBorder="0"
                                            style={{ border: 0 }}
                                            src={mapUrl}
                                            allowFullScreen=""
                                            aria-hidden="false"
                                            tabIndex="0"
                                        />
                                    </div>
                                </div>
                            </div>
        
                            {/* Information Grid (2/3 of the width) */}
                            <div className="information-grid col-span-2 grid grid-cols-1 gap-4 md:grid-cols-2 p-4">
                                {information.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-start py-3 border-b border-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                                    >
                                        {/* Icon Section */}
                                        <div className="text-gray-500 flex items-center">
                                            {item.icon}
                                        </div>
        
                                        {/* Label and Value Section */}
                                        <div className="ml-4 flex-1">
                                            <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{item.value}</dd>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
        
        
                    {/* Edit Profile Button */}
                    <div className="flex justify-end m-6 px-2">
                        <button
                            type="button"
                            onClick={handleEditClick}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-xs leading-5 font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                        >
                            <AiFillEdit className="mr-2" />
                            Edit Profile
                        </button>
                    </div>
                </div>
            </div>
        );
        
    }
};

export default Profile;
