// AccessDetail.js

import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import { FaLock } from 'react-icons/fa';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import loadingAnimation from '../../lottie/loading.json';
import errorAnimation from '../../lottie/noLinks.json';
import { useUser } from "../../context";
import { FaDesktop, FaMobileAlt, FaTabletAlt, FaMapMarkerAlt } from 'react-icons/fa';

const AccessDetail = ({ loginId, onBack, setActiveComponent }) => {
    const { user } = useUser();
    const [loginDetails, setLoginDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchLoginDetails = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/api/auth/login-history/${loginId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });

                if (response.status === 200 && response.data) {
                    setLoginDetails(response.data);
                } else {
                    setError('Failed to fetch login details');
                }
            } catch (error) {
                console.error("Error fetching login details:", error);
                setError('An error occurred while fetching login details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchLoginDetails();
    }, [loginId, user.token]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const getDeviceIcon = (device) => {
        switch (device.toLowerCase()) {
            case 'desktop':
                return <FaDesktop className="mr-1 text-blue-500" />;
            case 'mobile':
                return <FaMobileAlt className="mr-1 text-green-500" />;
            case 'tablet':
                return <FaTabletAlt className="mr-1 text-purple-500" />;
            default:
                return <FaDesktop className="mr-1 text-gray-500" />;
        }
    };

    if (isLoading) {
        return (
            <div className="container mx-auto p-4 bg-white rounded-lg border shadow-xl">
                <div className="flex justify-center items-center">
                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                </div>
            </div>
        );
    }

    if (error || !loginDetails) {
        return (
            <div className="container mx-auto p-4 bg-white rounded-lg border shadow-xl">
                <div className="flex flex-col justify-center items-center">
                    <Lottie animationData={errorAnimation} style={{ width: 200, height: 200 }} />
                    <p className="mt-4 text-sm font-semibold text-gray-600">{error || "No login details found."}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg border shadow-xl" style={{
            backgroundImage: 'url(https://res.cloudinary.com/dx6jw8k0m/image/upload/v1722095645/roating_spinner_hpjygd.gif)',
            backgroundSize: 'cover',  // Ensures the image covers the entire container
            backgroundPosition: 'center center',  // Centers the image both horizontally and vertically
            backgroundRepeat: 'no-repeat',  // Prevents the image from repeating
            backgroundBlendMode: 'overlay',
            backgroundColor: 'rgba(255, 255, 255, 0.4)'
        }}>
            <div className="border-b border-dotted pb-4">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                    <button onClick={onBack} className="flex items-center justify-center p-2 text-emerald-600 hover:text-emerald-800 transition-colors duration-150 ease-in-out mb-4 lg:mb-0 lg:mr-4">
                        <span className="inline-flex items-center justify-center p-2 mr-2 rounded-full border border-green-600 bg-green-100 hover:bg-green-200">
                            <AiOutlineArrowLeft />
                        </span>
                        Back
                    </button>
                    <p className="text-xs text-center text-gray-600 flex-1">Please review the Access history below.</p>
                </div>
            </div>

            <div className="pt-4 text-xs">
                <div className="flex justify-between mb-4">
                    <p className="flex items-center">
                        <strong>Location:</strong>
                    </p>
                    <p className="flex items-center">
                        <FaMapMarkerAlt className="mr-1 animate-pulse text-red-500" />
                        {loginDetails.locationName || 'Unknown'}
                    </p>
                </div>

                <div className="flex justify-between mb-4">
                    <p><strong>Date:</strong></p>
                    <p>{formatDate(loginDetails.loginTime)}</p>
                </div>

                <div className="flex justify-between mb-4">
                    <p className="flex items-center">
                        <strong>Device:</strong>
                    </p>
                    <p className="flex items-center">
                        {getDeviceIcon(loginDetails.device)} {/* Device icon based on type */}
                        {loginDetails.device}
                    </p>
                </div>

                <div className="flex justify-between mb-4">
                    <p><strong>Operating System:</strong></p>
                    <p>{loginDetails.operatingSystem}</p>
                </div>

                <div className="flex justify-between mb-4">
                    <p><strong>Browser:</strong></p>
                    <p>{loginDetails.browser}</p>
                </div>

                <div className="flex justify-between mb-4">
                    <p><strong>Platform:</strong></p>
                    <p>{loginDetails.platform}</p>
                </div>

            </div>


            <div className="mt-4 py-4 border-t text-xs text-gray-600 text-center rounded bg-gray-100">
                <p>
                    If you don't recognize this login,
                    <span
                        onClick={() => setActiveComponent('changePassword')} // Set active component to changePassword
                        className="text-blue-600 hover:text-blue-800 cursor-pointer">
                        change your password
                    </span>.
                </p>
                <div className="flex justify-center items-center mt-2">
                    <FaLock className="text-green-600 mr-2" />
                    <span>Your account is secured</span>
                </div>
            </div>

        </div>
    );
};

export default AccessDetail;
