import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaTrashAlt } from 'react-icons/fa'; // Import trash icon

const UserConversions = ({ userId }) => {
    const { user } = useUser();
    const [conversions, setConversions] = useState([]);
    const [selectedConversions, setSelectedConversions] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false); // State to mute button during deletion

    useEffect(() => {
        const fetchConversions = async () => {
            try {
                const response = await api.get(`/api/customer/conversions/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setConversions(response.data);
                } else {
                    toast.error('Failed to load conversion data');
                }
            } catch (error) {
                console.error("Error fetching conversion data: ", error);
                toast.error(`Failed to load conversion data: ${error.message}`);
            }
        };

        fetchConversions();
    }, [userId, user.token]);

    // Handle checkbox selection
    const handleCheckboxChange = (conversionId) => {
        if (selectedConversions.includes(conversionId)) {
            setSelectedConversions(selectedConversions.filter(id => id !== conversionId));
        } else {
            setSelectedConversions([...selectedConversions, conversionId]);
        }
    };

    // Handle delete click
    const handleDeleteClick = () => {
        if (selectedConversions.length > 0) {
            setShowDeleteModal(true);
        } else {
            toast.error("Please select at least one conversion to delete.");
        }
    };

    // Confirm deletion
    const handleDelete = async () => {
        setIsDeleting(true); // Mute the button
        try {
            const response = await api.delete(`/api/customer/conversions/${userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
                data: { conversionIds: selectedConversions },
            });
            if (response.status === 200) {
                toast.success(`${selectedConversions.length} conversion(s) deleted successfully`);
                setConversions(conversions.filter(conv => !selectedConversions.includes(conv._id)));
                setSelectedConversions([]);
                setShowDeleteModal(false);
            } else {
                toast.error('Failed to delete conversions');
            }
        } catch (error) {
            console.error("Error deleting conversions: ", error);
            toast.error(`Failed to delete conversions: ${error.message}`);
        }
        setIsDeleting(false); // Unmute the button
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <h3 className="font-semibold text-lg mb-4">Conversions</h3>
            <div className="flex items-center justify-between mb-4">
                {selectedConversions.length > 0 && (
                    <button
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                        className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4 flex items-center ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <FaTrashAlt className="mr-2" />
                        {selectedConversions.length}
                    </button>
                )}
            </div>

            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedConversions(conversions.map(conv => conv._id));
                                        } else {
                                            setSelectedConversions([]);
                                        }
                                    }}
                                />
                            </th>
                            <th className="px-4 py-2">Transaction ID</th>
                            <th className="px-4 py-2">From Currency</th>
                            <th className="px-4 py-2">To Currency</th>
                            <th className="px-4 py-2">From Amount</th>
                            <th className="px-4 py-2">To Amount</th>
                            <th className="px-4 py-2">Rate</th>
                            <th className="px-4 py-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conversions.map((conversion) => (
                            <tr key={conversion._id} className="border-b">
                                <td className="px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedConversions.includes(conversion._id)}
                                        onChange={() => handleCheckboxChange(conversion._id)}
                                    />
                                </td>
                                <td className="px-4 py-2">{conversion.transactionId}</td>
                                <td className="px-4 py-2">{conversion.fromCurrency}</td>
                                <td className="px-4 py-2">{conversion.toCurrency}</td>
                                <td className="px-4 py-2">{conversion.fromAmount}</td>
                                <td className="px-4 py-2">{conversion.toAmount}</td>
                                <td className="px-4 py-2">{conversion.conversionRate}</td>
                                <td className="px-4 py-2">
                                    {new Date(conversion.createdAt).toLocaleString()}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        <p className="my-4">
                            Are you sure you want to delete {selectedConversions.length} entries of this user?
                        </p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isDeleting}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserConversions;
