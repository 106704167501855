// components/Admin/campaigns/CampaignList.js

import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const CampaignList = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [campaigns, setCampaigns] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [campaignsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState(null);

    useEffect(() => {
        fetchCampaigns();
    }, [currentPage, searchTerm]);

    const fetchCampaigns = async () => {
        setLoading(true);
        const queryParams = { page: currentPage, limit: campaignsPerPage };

        if (searchTerm) {
            queryParams.title = searchTerm;
        }

        try {
            const response = await api.get('/api/campaigns/', {
                params: queryParams,
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setCampaigns(response.data.campaigns || []);
            setTotalPages(response.data.totalPages || 0);
        } catch (error) {
            console.error("Error fetching campaigns: ", error);
            setCampaigns([]);
        }
        setLoading(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchCampaigns();
    };

    const handleDeleteCampaignClick = (campaign) => {
        setCampaignToDelete(campaign);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (campaignToDelete) {
            try {
                await api.delete(`/api/campaigns/${campaignToDelete._id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                toast.success("Campaign deleted successfully");
                setShowDeleteModal(false);
                fetchCampaigns();
            } catch (error) {
                toast.error("Failed to delete campaign");
            }
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'active':
                return 'text-green-500';
            case 'test':
                return 'text-yellow-500';
            case 'pause':
                return 'text-gray-500';
            case 'inactive':
                return 'text-red-500';
            default:
                return '';
        }
    };

    const getPagination = () => {
        const delta = 1;
        const range = [];
        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }
        if (currentPage - delta > 2) {
            range.unshift("...");
        }
        if (currentPage + delta < totalPages - 1) {
            range.push("...");
        }
        range.unshift(1);
        if (totalPages !== 1) range.push(totalPages);

        return range.map((page, index) => page === "..." ? <span key={index} className="px-2">...</span> : <button key={index} onClick={() => setCurrentPage(page)} className={`px-2 py-1 ${page === currentPage ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}>{page}</button>);
    };

    return (
        <div className="container mx-auto px-4 pb-20">
            <ToastContainer />
            <form onSubmit={handleSearch} className="mb-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mt-3">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by title..."
                    className="border p-2 rounded text-xs flex-grow"
                />
                <button type="submit" className="border p-2 rounded bg-emerald-500 text-white text-xs">
                    Search
                </button>
            </form>
            <div className="overflow-x-auto">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <FontAwesomeIcon icon={faSpinner} spin className="text-2xl" />
                    </div>
                ) : campaigns.length > 0 ? (
                    <table className="w-full border-collapse text-xs">
                        <thead>
                            <tr className="bg-gray-200 text-xs">
                                <th className="border p-2">Created At</th>
                                <th className="border p-2">Title</th>
                                <th className="border p-2">Total sent</th>
                                <th className="border p-2">Status</th>
                                <th className="border p-2">Join After</th>
                                <th className="border p-2">Join Before</th>
                                <th className="border p-2">Days Old</th>
                                <th className="border p-2 text-center">Edit</th>
                                <th className="border p-2 text-center">Delete</th>
                            </tr>
                        </thead>
                        <tbody >
                            {campaigns.map((campaign) => (
                                <tr key={campaign._id}>
                                    <td className="border p-2 text-xs">{moment(campaign.createdAt).format('LL')}</td>
                                    <td className="border p-2 text-xs">{campaign.title}</td>
                                    <td className="border p-2 text-xs">{campaign.totalSent}</td>
                                    <td className={`border p-2 text-xs ${getStatusColor(campaign.status)}`}>{campaign.status}</td>
                                    <td className="border p-2 text-xs">{campaign.joinAfter ? moment(campaign.joinAfter).format('LL') : 'N/A'}</td>
                                    <td className="border p-2 text-xs">{campaign.joinBefore ? moment(campaign.joinBefore).format('LL') : 'N/A'}</td>
                                    <td className="border p-2 text-xs">{campaign.daysOld || 'N/A'}</td>
                                    <td className="border p-2 text-center">
                                        <button
                                            onClick={() => navigate(`/campaigns-edit/${campaign._id}`)}
                                            className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                    <td className="border p-2 text-center">
                                        <button
                                            onClick={() => handleDeleteCampaignClick(campaign)}
                                            className="px-4 py-2 text-white rounded text-xs bg-red-600 hover:bg-red-700"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="flex justify-center items-center">
                        <p className="text-lg">No campaigns found</p>
                    </div>
                )}

                <div className="flex justify-center space-x-2 mt-4">
                    {getPagination()}
                </div>
            </div>

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        <p className="my-4">Are you sure you want to delete the campaign "{campaignToDelete?.title}"?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">Cancel</button>
                            <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CampaignList;
