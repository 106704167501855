//dashboard.js
import React, { useState, useEffect } from 'react';
import { useUser } from "./context";
import { useNavigate } from 'react-router-dom';
import api from '../api';
import Sidebar from './Sidebar';
import ChangePassword from './ChangePassword';
import DonationsSummary from './DonationsSummary';
import MyDonations from './MyDonations';
import VolunteerActivities from './VolunteerActivities';
import Profile from './Profile';
import Membership from './Membership';
import Wallet from './wallet/Wallet';
import Kyc from './Kyc';
import AccessHistory from './wallet/history/AccessHistory';
import AccessDetail from './wallet/history/AccessDetail';
import ForexStrip from './TradingViewWidget';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, login } = useUser();
  // State to manage which component is displayed
  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  const [selectedLoginId, setSelectedLoginId] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      // Fetch updated user data when the dashboard is visited
      const fetchUserData = async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
            }
          };
          const response = await api.get('/api/auth/info', config);
          if (response.status === 200) {
            login({ ...user, ...response.data });
          } else {
            console.error('Failed to fetch user data, status code:', response.status);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
    }
  }, []);


  useEffect(() => {
    // This useEffect checks both the ip and firstName fields
    if (!user?.primaryInfo?.ip || !user?.primaryInfo?.firstName) {
      setActiveComponent('kyc');
      // Redirect to KYC page if either ip or firstName is not set
      navigate('/kyc');
    }
  }, [user, navigate]);
  
  const handleViewDetails = (loginId) => {
    setSelectedLoginId(loginId); // Set the selected login ID
    setActiveComponent('accessDetail'); // Switch to the detail view
  };

  const handleBackToHistory = () => {
    setSelectedLoginId(null); // Clear selected login ID
    setActiveComponent('accessHistory'); // Switch back to history view
  };
  

  // Object to map component keys to component render functions
  const componentMap = {
    donationsSummary: <DonationsSummary setActiveComponent={setActiveComponent} />,
    myDonations: <MyDonations />,
    volunteerActivities: <VolunteerActivities />,
    changePassword: <ChangePassword />,
    profile: <Profile />,
    membership: <Membership />,
    wallet: <Wallet />,
    kyc: <Kyc />,
    accessHistory: <AccessHistory onViewDetails={handleViewDetails} />,
    accessDetail: <AccessDetail loginId={selectedLoginId} onBack={handleBackToHistory} setActiveComponent={setActiveComponent} />,
  };




  return (
    <div className="bg-emerald-50 min-h-screen pb-20 font-harmattan font-12" >
      <ForexStrip />
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center text-xs" role="alert">
        <strong className="font-bold">🎉 Great News! 🎊</strong>
        <span className="block sm:inline">
          You can now call us for assistance. Reach out to our support team for prompt help at <a href="tel:+13852339891" className="underline">+1 385 233 9891</a>.
        </span>
      </div>
      {/* Main content 
      <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative text-center text-xs mt-1" role="alert">
        <strong className="font-bold">⚠️ Important Notice ⚠️</strong>
        <span className="block sm:inline">
        Effective July 1, 2024, it will be necessary for you to Verify your account using official National documentation in accordance with the Anti-Money Laundering (AML) regulations policy.
        </span>
      </div>
      */}
      <div className="lg:flex lg:flex-row p-4 rounded-lg border border-gray-200">
        <Sidebar changeComponent={setActiveComponent} />

        {/* Main content */}
        <div className="mx-4 mt-8 rounded-lg flex-grow flex flex-col font-harmattan font-12">
          {/* Render the active component */}
          {componentMap[activeComponent]}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
