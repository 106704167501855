// MyDonations.js
import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";
import cryingEmoji from "./lottie/crying-emoji.json";
import emptyAnimation from "./lottie/noLinks.json";
import loadingAnimation from './lottie/loading.json';
import api from '../api';
import { useUser } from './context';
import CreateDonationLink from './CreateDonationLink';
import DonationsPreviewPage from './DonationsPreviewPage';

// Helper function for date formatting
const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert to Date object
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }); // Output in "23 Dec 2022" format
};

const getStatusClasses = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-blue-800';
      case 'approved':
        return 'bg-blue-100 text-green-800';
      case 'completed':
        return 'bg-purple-100 text-purple-800';
      case 'inactive':
        return 'bg-gray-100 text-gray-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'test':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  
const MyDonations = () => {
    const { user } = useUser();
    const [donationLinks, setDonationLinks] = useState([]);
    const [activeTab, setActiveTab] = useState('links');
    const [isLoading, setIsLoading] = useState(false);
    const [donations, setDonations] = useState([]);
    const [showCreateLink, setShowCreateLink] = useState(false);
    const [selectedLinkId, setSelectedLinkId] = useState(null);



    useEffect(() => {
        const fetchDonationLinks = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/donations/user-links', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setDonationLinks(response.data);
            } catch (error) {
                console.error("Error fetching donation links: ", error);
            }
            setIsLoading(false);
        };

        fetchDonationLinks();
    }, [user.token]);

    useEffect(() => {
        const fetchDonations = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/donations/user-donations', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setDonations(response.data);
            } catch (error) {
                console.error("Error fetching donations: ", error);
            }
            setIsLoading(false);
        };

        fetchDonations();
    }, [user.token]);

    // Handlers for tab changes
    const showHistory = () => setActiveTab('history');
    const showLinks = () => setActiveTab('links');

    const viewDonationLink = (linkId) => {
        setSelectedLinkId(linkId); // Set the selected link ID when "View" is clicked
    };

    const handleCreateLinkClick = () => {
        setShowCreateLink(true);
    };

    const handleBackToLinks = () => {
        setSelectedLinkId(null); // Clear the selected link ID to go back to the list
    };

    // Truncate function
    const truncate = (text, length) => {
        return text.length > length ? `${text.substring(0, length)}...` : text;
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg shadow flex flex-col flex-grow">
            {/* Tabs for switching between history and links */}
            {!selectedLinkId && (
                <div className="flex mb-4">
                    <button
                        onClick={showLinks}
                        className={`flex-1 py-2 text-center ${activeTab === 'links' ? 'text-emerald-500 border-b-2 border-emerald-500' : 'text-gray-500'}`}
                    >
                        Donation Links
                    </button>
                    <button
                        onClick={showHistory}
                        className={`flex-1 py-2 text-center ${activeTab === 'history' ? 'text-emerald-500 border-b-2 border-emerald-500' : 'text-gray-500'}`}
                    >
                        History
                    </button>
                </div>
            )}

            {/* Conditionally render CreateDonationLink */}
            {showCreateLink ? (
                <CreateDonationLink setShowCreateLink={setShowCreateLink} />
            ) : selectedLinkId ? (
                <div>
                    <button
                        onClick={handleBackToLinks}
                        className="mb-4 mx-auto bg-emerald-500 bg-opacity-80 text-white p-4 rounded-full flex items-center justify-center w-12 h-12 hover:bg-opacity-100 transition-opacity duration-300"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>


                    <DonationsPreviewPage linkId={selectedLinkId} />
                </div>
            ) : (
                <>

                    {/* Render based on activeTab state */}
                    {activeTab === 'history' && (
                        <div>
                            {donations.length > 0 ? (
                                donations.map((donation, index) => (
                                    <div key={index} className="flex items-center justify-between p-2 mt-3 border rounded">
                                        <div className="flex items-center space-x-3">
                                            {/* Replace image with a div containing the first letter of the donor's name */}
                                            <div className="flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-full bg-emerald-200 text-white shadow-lg">
                                                <span className="text-xl font-bold">{donation.firstName.charAt(0)}</span>
                                            </div>


                                            <div>
                                                <p className="font-semibold text-xs">{donation.firstName} {donation.lastName}</p>
                                                <p className="text-xs text-gray-500">{`${formatDate(donation.date)} · ${donation.paymentStatus}`}</p>
                                            </div>
                                        </div>
                                        <span className={`font-bold text-xs text-green-500`}>+${donation.amount}</span>
                                    </div>
                                ))
                            ) : (
                                <div className="flex justify-center items-center flex-col">
                                    <Lottie animationData={cryingEmoji} style={{ width: 200, height: 200 }} />
                                    <p className='text-gray-500 font-semibold mt-4'>No donations yet</p>
                                    <button
                                        onClick={handleCreateLinkClick}
                                        className="w-full text-sm sm:w-auto border border-emerald-500 text-emerald-500 px-4 py-1 rounded hover:bg-emerald-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300 inline-flex items-center justify-center"
                                    >
                                        Create a Donation Link
                                    </button>
                                </div>
                            )}

                        </div>
                    )}

                    {activeTab === 'links' && (
                        <div>

                            {/* Donation Links List */}
                            {isLoading ? (
                                <div className="flex justify-center items-center py-4">
                                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                                </div>
                            ) : donationLinks.length > 0 ? (
                                donationLinks.map((link, index) => (
                                    <div key={index} className="flex flex-col md:flex-row items-center justify-between p-4 border rounded-lg mb-2 shadow space-y-4 md:space-y-0">
                                        {/* Profile Picture and Link Details */}
                                        <div className="w-full md:flex md:items-center md:space-x-4 flex-1 min-w-0 text-center md:text-left">
                                            {/* Profile picture centered on small screens and left-aligned on larger screens */}
                                            <img src={link.image} alt="Profile" className="w-10 h-10 rounded-full mx-auto md:mx-0" />
                                            {/* Adjustments for text */}
                                            <div className="flex-1 min-w-0">
                                                <h4 className="font-semibold text-sm sm:text-base truncate">{truncate(link.title, 50)}</h4>
                                                <p className="text-xs sm:text-sm text-gray-500 truncate w-full max-w-full">{truncate(link.description, 100)}</p>
                                            </div>
                                        </div>

                                        {/* Status and Action */}
                                        <div className="flex flex-col sm:flex-row items-center sm:items-center w-full md:w-auto text-center">
                                            <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full mb-2 sm:mb-0 ${getStatusClasses(link.status)}`}>
                                                {link.status.charAt(0).toUpperCase() + link.status.slice(1)}
                                            </span>

                                            <button
                                                onClick={() => viewDonationLink(link._id)} // On click, set the selected link ID
                                                className="mt-2 sm:mt-0 sm:ml-3 text-xs md:text-sm border border-emerald-500 text-emerald-500 hover:bg-emerald-500 hover:text-white transition-colors duration-300 py-1 px-3 rounded w-full md:w-auto"
                                            >
                                                View
                                            </button>
                                        </div>

                                    </div>
                                ))
                            ) : (
                                <div className="flex justify-center items-center flex-col">
                                    <Lottie animationData={emptyAnimation} style={{ width: 200, height: 200 }} />
                                    <p className="text-gray-500 font-semibold mt-4">No links created</p>
                                    <button
                                        onClick={handleCreateLinkClick}
                                        className="w-full text-sm sm:w-auto border border-emerald-500 text-emerald-500 px-4 py-1 rounded hover:bg-emerald-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300 inline-flex items-center justify-center"
                                    >
                                        Create a Donation Link
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}

            {/* Image Section Only Visible on Mobile */}
            <div className="block sm:hidden mt-4">
                <img
                    src="https://res.cloudinary.com/dx6jw8k0m/image/upload/v1726612317/Black_Colorful_Volunteers_Raise_Funds_Billboard_Email_Header_vh1e6q.png"
                    alt="Volunteers Raise Funds"
                    className="lg:hidden w-full h-auto my-2 rounded-lg shadow-lg border border-gray-200"
                />
            </div>
        </div>
    );
};

export default MyDonations;




