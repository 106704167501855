import React, { useState, useEffect } from 'react';
import api from '../api';
import CircleLoader from 'react-spinners/CircleLoader';
import Hero from './Hero';
import Impact from './Impact';
import GeneralFooter from './GeneralFooter';
import { useUser } from './context';

function Home() {
  const { user } = useUser();
  const [impacts, setImpacts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImpacts = async () => {
      setLoading(true);
      try {
        const headers = {};
        if (user && user.token) {
          headers['Authorization'] = `Bearer ${user.token}`;
        }

        const response = await api.get('/api/impacts', { headers });
        setImpacts(response.data);
      } catch (error) {
        console.error("Error fetching impacts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImpacts();
  }, []);

  return (
    <div className="flex flex-col min-h-screen pb-20 ">
    {/* Hero Section with bottom margin */}
    <div className="">
      <Hero />
    </div>
  
    {/* Rotating Logos Section */}
    <div className="overflow-hidden mb-4 bg-gray-100">
      <div className="animate-scroll flex items-center shadow-lg space-x-10">
        {/* Repeat the company logos multiple times to ensure seamless scroll */}
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-2.1ea7d28d.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-3.4922153e.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        {/* Repeat the company logos multiple times to ensure seamless scroll */}
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-2.1ea7d28d.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-3.4922153e.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        <img
          src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
          alt="Company Logos"
          className="h-16 w-auto"
        />
        {/* Add more logos if needed */}
      </div>
    </div>
  


{/* Video Section Only Visible on Mobile */}
<div className="block sm:hidden py-6">
  <video
    autoPlay
    muted
    loop
    playsInline
    className="w-full h-auto"
  >
    <source src="/dashboard_images/Open_Donation_Video.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>


    {/* Title for Impacts Section with padding and bottom margin */}
    <div className="p-4 mb-8">
      <h2 className="text-2xl font-bold text-center mb-8">Our Impact</h2>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <CircleLoader />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5">
          {impacts.map((impact, index) => (
            <Impact key={index} {...impact} userHasLiked={impact.userHasLiked} />
          ))}
        </div>
      )}
    </div>
  </div>
  
  );
}

export default Home;