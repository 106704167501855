// components/Admin/campaigns/CampaignCreate.js

import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api';
import { useUser } from '../../context';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const CampaignCreate = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [campaignData, setCampaignData] = useState({
    title: '',
    subject: '',
    content: '',
    imageUrl: '',
    notificationType: 'both',
    status: 'inactive',
    testEmails: [],
    joinAfter: '',
    joinBefore: '',
    daysOld: 0,
    frequency: 'once',
    customFrequency: 0,
    stopSendingDate: '',
    userQueryLimit: 50,
    imageMethod: 'url',
    imageFile: null,
  });

  const [testEmail, setTestEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [dateError, setDateError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignData({ ...campaignData, [name]: value });
  
    if (name === 'joinAfter' || name === 'joinBefore') {
      if (campaignData.joinAfter && campaignData.joinBefore) {
        if (new Date(campaignData.joinAfter) > new Date(campaignData.joinBefore)) {
          setDateError('Join After date cannot be after Join Before date');
        } else {
          setDateError('');
        }
      }
    }
  };
  

  const handleContentChange = (value) => {
    setCampaignData({ ...campaignData, content: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCampaignData({ ...campaignData, imageFile: e.target.files[0], imageUrl: '' });
    }
  };


  const handleAddTestEmail = () => {
    if (testEmail && /\S+@\S+\.\S+/.test(testEmail)) {
      setCampaignData({ ...campaignData, testEmails: [...campaignData.testEmails, testEmail] });
      setTestEmail('');
    } else {
      toast.error('Please enter a valid email address');
    }
  };

  const handleRemoveTestEmail = (email) => {
    setCampaignData({ ...campaignData, testEmails: campaignData.testEmails.filter((e) => e !== email) });
  };




  const removeImage = (e) => {
    e.preventDefault();
    setCampaignData(prevData => ({
      ...prevData,
      imageFile: null
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };


  const uploadImage = async () => {
    const data = new FormData();
    data.append('file', campaignData.imageFile);
    data.append('upload_preset', 'ml_default');
    data.append('cloud_name', 'dx6jw8k0m');

    try {
      const response = await axios.post('https://api.cloudinary.com/v1_1/dx6jw8k0m/image/upload', data);
      return response.data.secure_url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Failed to upload image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (dateError) {
      toast.error(dateError);
      setSubmitting(false);
      return;
    }

    try {
      if (campaignData.imageMethod === 'upload' && campaignData.imageFile) {
        const imageUrl = await uploadImage();
        campaignData.imageUrl = imageUrl; // Set the imageUrl from the uploaded image
      }

      const response = await api.post('/api/campaigns', campaignData, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (response.status === 201) {
        toast.success('Campaign created successfully');
        navigate('/admin/campaigns');
      } else {
        toast.error('Failed to create campaign');
      }
    } catch (error) {
      toast.error(`Error creating campaign: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow pb-20">
      <ToastContainer />
      <h2 className="text-xl font-semibold mb-6">Create Campaign</h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="notificationType" className="block text-xs font-medium text-gray-700">Notification Type</label>
          <select
            id="notificationType"
            name="notificationType"
            value={campaignData.notificationType}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          >
            <option value="email">Email</option>
            <option value="sms">SMS</option>
            <option value="both">Both</option>
          </select>
        </div>
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="title" className="block text-xs font-medium text-gray-700">Title</label>
          <input
            id="title"
            type="text"
            name="title"
            value={campaignData.title}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          />
        </div>
        {campaignData.notificationType !== 'sms' && (
          <div className="sm:col-span-2 md:col-span-1">
            <label htmlFor="subject" className="block text-xs font-medium text-gray-700">Subject</label>
            <input
              id="subject"
              type="text"
              name="subject"
              value={campaignData.subject}
              onChange={handleChange}
              className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
            />
          </div>
        )}
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="imageMethod" className="block text-xs font-medium text-gray-700">Image Method</label>
          <select
            id="imageMethod"
            name="imageMethod"
            value={campaignData.imageMethod}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          >
            <option value="url">Image URL</option>
            <option value="upload">Upload Image</option>
          </select>
        </div>

        {campaignData.imageMethod === 'url' ? (
          <div className="sm:col-span-2 md:col-span-1">
            <label htmlFor="imageUrl" className="block text-xs font-medium text-gray-700">Image URL</label>
            <input
              id="imageUrl"
              type="text"
              name="imageUrl"
              value={campaignData.imageUrl}
              onChange={handleChange}
              className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
            />
          </div>
        ) : (
          <div className="sm:col-span-2 md:col-span-1">
            <label htmlFor="imageFile" className="block text-xs font-medium text-gray-700">Upload Image</label>
            <div
              className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer hover:border-emerald-500 transition-colors"
              onClick={() => fileInputRef.current.click()}
            >
              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H8m36-12h-4m4 0H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="mt-2 block text-xs sm:text-sm font-medium text-gray-900">
                Upload Picture
              </span>
              <input
                ref={fileInputRef}
                id="imageFile"
                type="file"
                name="imageFile"
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
            {campaignData.imageFile && (
              <div className="mt-4 flex flex-wrap justify-start items-center w-full">
                <div className="flex flex-col items-center mr-4 mb-4">
                  <img src={URL.createObjectURL(campaignData.imageFile)} alt="User Profile" className="w-16 h-16 object-cover rounded-md" />
                  <button onClick={removeImage} className="mt-2 text-red-500">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}


        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="status" className="block text-xs font-medium text-gray-700">Status</label>
          <select
            id="status"
            name="status"
            value={campaignData.status}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          >
            <option value="active">Active</option>
            <option value="test">Test</option>
            <option value="pause">Pause</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        {campaignData.status === 'test' && (
          <div className="sm:col-span-2 md:col-span-2">
            <label htmlFor="testEmails" className="block text-xs font-medium text-gray-700">Test Emails</label>
            <div className="flex space-x-2">
              <input
                id="testEmails"
                type="email"
                name="testEmail"
                value={testEmail}
                onChange={(e) => setTestEmail(e.target.value)}
                className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
              />
              <button
                type="button"
                onClick={handleAddTestEmail}
                className="mt-1 px-4 py-2 bg-emerald-500 text-white text-xs rounded-md focus:outline-none"
              >
                Add
              </button>
            </div>
            <ul className="mt-2">
              {campaignData.testEmails.map((email) => (
                <li key={email} className="flex justify-between items-center">
                  <span>{email}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveTestEmail(email)}
                    className="px-2 py-1 bg-red-500 text-white text-xs rounded-md focus:outline-none"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="joinAfter" className="block text-xs font-medium text-gray-700">Join After</label>
          <input
            id="joinAfter"
            type="date"
            name="joinAfter"
            value={campaignData.joinAfter}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          />
          {dateError && <span className="text-red-500 text-xs">{dateError}</span>}
        </div>
        <div className="sm:col-span-2 md:col-span-1">
      <label htmlFor="daysOld" className="block text-xs font-medium text-gray-700">Days Old</label>
      <input
        id="daysOld"
        type="number"
        name="daysOld"
        value={campaignData.daysOld}
        onChange={handleChange}
        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
      />
    </div>
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="joinBefore" className="block text-xs font-medium text-gray-700">Join Before</label>
          <input
            id="joinBefore"
            type="date"
            name="joinBefore"
            value={campaignData.joinBefore}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
            min={campaignData.joinAfter} // Ensures joinBefore cannot be before joinAfter
          />
          {dateError && <span className="text-red-500 text-xs">{dateError}</span>}
        </div>
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="frequency" className="block text-xs font-medium text-gray-700">Frequency</label>
          <select
            id="frequency"
            name="frequency"
            value={campaignData.frequency}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          >
            <option value="once">Once</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        {campaignData.frequency === 'custom' && (
          <div className="sm:col-span-2 md:col-span-1">
            <label htmlFor="customFrequency" className="block text-xs font-medium text-gray-700">Custom Frequency (days)</label>
            <input
              id="customFrequency"
              type="number"
              name="customFrequency"
              value={campaignData.customFrequency}
              onChange={handleChange}
              className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
            />
          </div>
        )}
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="stopSendingDate" className="block text-xs font-medium text-gray-700">Stop Sending Date</label>
          <input
            id="stopSendingDate"
            type="date"
            name="stopSendingDate"
            value={campaignData.stopSendingDate}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          />
        </div>
        <div className="sm:col-span-2 md:col-span-1">
          <label htmlFor="userQueryLimit" className="block text-xs font-medium text-gray-700">User Query Limit</label>
          <input
            id="userQueryLimit"
            type="number"
            name="userQueryLimit"
            value={campaignData.userQueryLimit}
            onChange={handleChange}
            className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
          />
        </div>
        <div className="sm:col-span-2 md:col-span-2">
          <label htmlFor="content" className="block text-xs font-medium text-gray-700">Content</label>
          <ReactQuill
            theme="snow"
            value={campaignData.content}
            onChange={handleContentChange}
            className="mt-1"
            style={{ height: '150px', marginBottom: '16px' }}
          />
        </div>
        <div className="col-span-full flex justify-end mt-4">
          <button
            type="submit"
            className={`px-4 py-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${submitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-emerald-500 hover:bg-emerald-600 focus:ring-emerald-500'
              }`}
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Create Campaign'}
          </button>
        </div>

      </form>
    </div>
  );
};

export default CampaignCreate;
