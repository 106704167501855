import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';

const UserWithdrawals = ({ userId }) => {
    const { user } = useUser();
    const [withdrawals, setWithdrawals] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [withdrawalToDelete, setWithdrawalToDelete] = useState(null);

    useEffect(() => {
        const fetchWithdrawals = async () => {
            try {
                const response = await api.get(`/api/customer/${userId}/withdrawals`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setWithdrawals(response.data);
                } else {
                    toast.error('Failed to load withdrawal data');
                }
            } catch (error) {
                console.error("Error fetching withdrawal data: ", error);
                toast.error(`Failed to load withdrawal data: ${error.message}`);
            }
        };

        fetchWithdrawals();
    }, [userId, user.token]);

    const handleDeleteClick = (withdrawal) => {
        setWithdrawalToDelete(withdrawal);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        if (withdrawalToDelete) {
            try {
                const response = await api.delete(`/api/customer/${userId}/withdrawals/${withdrawalToDelete._id}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    toast.success('Withdrawal successfully deleted');
                    setWithdrawals(withdrawals.filter(w => w._id !== withdrawalToDelete._id));
                    setShowDeleteModal(false);
                } else {
                    toast.error('Failed to delete withdrawal');
                }
            } catch (error) {
                console.error("Error deleting withdrawal: ", error);
                toast.error(`Failed to delete withdrawal: ${error.message}`);
            }
        }
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <h3 className="font-semibold text-lg mb-4">Withdrawals</h3>
            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">Withdrawal ID</th>
                            <th className="px-4 py-2">Amount</th>
                            <th className="px-4 py-2">Currency</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Date</th>
                            <th className="px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {withdrawals.map((withdrawal) => (
                            <tr key={withdrawal._id} className="border-b">
                                <td className="px-4 py-2">{withdrawal.withdrawalId}</td>
                                <td className="px-4 py-2">{withdrawal.amount}</td>
                                <td className="px-4 py-2">{withdrawal.currency}</td>
                                <td className="px-4 py-2">{withdrawal.status}</td>
                                <td className="px-4 py-2">
                                    {new Date(withdrawal.createdAt).toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => handleDeleteClick(withdrawal)}
                                        className="bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-4 rounded"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        <p className="my-4">Are you sure you want to delete this withdrawal?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">Cancel</button>
                            <button onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserWithdrawals;
