import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from '../../context';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const UserDonationLinks = ({ userId }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [donationLinks, setDonationLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDonationLinks = async () => {
            try {
                const response = await api.get(`/api/admin/user-donation-links/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setDonationLinks(response.data);
            } catch (error) {
                console.error('Error fetching donation links:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDonationLinks();
    }, [userId, user.token]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'active':
                return 'text-green-500';
            case 'approved':
                return 'text-blue-500';
            case 'completed':
                return 'text-gray-500';
            case 'inactive':
                return 'text-yellow-500';
            case 'cancelled':
                return 'text-red-500';
            case 'rejected':
                return 'text-red-700';
            case 'test':
                return 'text-purple-500';
            default:
                return 'text-gray-500';
        }
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <h2 className="text-xl font-semibold mb-6 text-xs">Donation Links</h2>
            {isLoading ? (
                <div className="flex justify-center items-center py-4">
                    <FaSpinner className="animate-spin text-4xl text-emerald-500" />
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="w-full border-collapse text-xs">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border p-2 text-xs">Title</th>
                                <th className="border p-2 text-xs">Unique Identifier</th>
                                <th className="border p-2 text-xs">Target Amount</th>
                                <th className="border p-2 text-xs">Total Donations</th>
                                <th className="border p-2 text-xs">Status</th>
                                <th className="border p-2 text-xs">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {donationLinks.length > 0 ? (
                                donationLinks.map((link) => (
                                    <tr key={link._id}>
                                        <td className="border p-2 text-xs">{link.title}</td>
                                        <td className="border p-2 text-xs">{link.uniqueIdentifier}</td>
                                        <td className="border p-2 text-xs">{link.targetAmount}</td>
                                        <td className="border p-2 text-xs">{link.totalDonations}</td>
                                        <td className={`border p-2 text-xs ${getStatusColor(link.status)}`}>{link.status}</td>
                                        <td className="border p-2 text-center text-xs">
                                            <button
                                                onClick={() => navigate(`/single-donation-link-details/${link._id}`)}
                                                className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="border p-2 text-center text-xs" colSpan="6">No donation links found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UserDonationLinks;
