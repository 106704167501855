
import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { useUser } from './context';


const Unauthorized = () => {
  const { logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };


    return (
        <div className="h-screen bg-gray-100 flex items-center justify-center">
            <div className="text-center p-6 bg-white shadow-xl m-2 text-xs  rounded-lg">
                <h1 className="text-2xl font-bold mb-2">Access Denied!</h1>
                <p className="mb-4">You must have taken a wrong turn at the last intersection. 🛑</p>
                <p>Just a friendly reminder: If you're trying to sneak into areas you shouldn't be, remember — curiosity killed the cat!</p>
                <p className="mt-4">If you think this is a mistake, please contact support or try logging in again.</p>
                <button className="mt-6 px-4 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-700 transition duration-300"
                      onClick={handleLogout}>
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default Unauthorized;
