import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DonationLinkEdit = () => {
    const { linkId } = useParams();
    const { user } = useUser();
    const navigate = useNavigate();
    const [linkData, setLinkData] = useState({
        title: '',
        targetAmount: 0,
        description: '',
        uniqueIdentifier: '',
        status: 'active',
        image: '',
        nextDonationTime: '',
        views: 0,
        totalDonations: 0,
        completionThreshold: 0,
    });
    const [originalData, setOriginalData] = useState({});
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchLinkData = async () => {
            try {
                const response = await api.get(`/api/admin/single-donation-link/${linkId}`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                if (response.status === 200) {
                    setLinkData(response.data);
                    setOriginalData(response.data);
                } else {
                    toast.error('Failed to load donation link data');
                }
            } catch (error) {
                toast.error(`Error fetching donation link data: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchLinkData();
    }, [linkId, user.token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLinkData({ ...linkData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const changedData = Object.keys(linkData).reduce((acc, key) => {
            if (linkData[key] !== originalData[key]) {
                acc[key] = linkData[key];
            }
            return acc;
        }, {});

        try {
            const response = await api.patch(`/api/admin/edit-donation-link/${linkId}`, changedData, {
                headers: { Authorization: `Bearer ${user.token}` },
            });

            if (response.status === 200) {
                toast.success('Donation link updated successfully');
                navigate(`/single-donation-link-details/${linkId}`);
            } else {
                toast.error('Failed to update donation link');
            }
        } catch (error) {
            toast.error(`Error updating donation link: ${error.message}`);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg shadow pb-20">
            <ToastContainer />
            <h2 className="text-xl font-semibold mb-6">Edit Donation Link</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="title" className="block text-xs font-medium text-gray-700">Title</label>
                    <input
                        id="title"
                        type="text"
                        name="title"
                        value={linkData.title}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="targetAmount" className="block text-xs font-medium text-gray-700">Target Amount</label>
                    <input
                        id="targetAmount"
                        type="number"
                        name="targetAmount"
                        value={linkData.targetAmount}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="views" className="block text-xs font-medium text-gray-700">Views</label>
                    <input
                        id="views"
                        type="number"
                        name="views"
                        value={linkData.views}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="totalDonations" className="block text-xs font-medium text-gray-700">Total Donations</label>
                    <input
                        id="totalDonations"
                        type="number"
                        name="totalDonations"
                        value={linkData.totalDonations}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="completionThreshold" className="block text-xs font-medium text-gray-700">Completion Threshold</label>
                    <input
                        id="completionThreshold"
                        type="number"
                        name="completionThreshold"
                        value={linkData.completionThreshold}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="uniqueIdentifier" className="block text-xs font-medium text-gray-700">Unique Identifier</label>
                    <input
                        id="uniqueIdentifier"
                        type="text"
                        name="uniqueIdentifier"
                        value={linkData.uniqueIdentifier}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="status" className="block text-xs font-medium text-gray-700">Status</label>
                    <select
                        id="status"
                        name="status"
                        value={linkData.status}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    >
                        <option value="active">Active</option>
                        <option value="approved">Approved</option>
                        <option value="completed">Completed</option>
                        <option value="inactive">Inactive</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="rejected">Rejected</option>
                        <option value="test">Test</option>
                    </select>
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="image" className="block text-xs font-medium text-gray-700">Image URL</label>
                    <input
                        id="image"
                        type="text"
                        name="image"
                        value={linkData.image}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-1">
                    <label htmlFor="nextDonationTime" className="block text-xs font-medium text-gray-700">Next Donation Time</label>
                    <input
                        id="nextDonationTime"
                        type="datetime-local"
                        name="nextDonationTime"
                        value={linkData.nextDonationTime ? new Date(linkData.nextDonationTime).toISOString().substring(0, 16) : ''}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        disabled={loading}
                    />
                </div>
                <div className="sm:col-span-2 md:col-span-2">
                    <label htmlFor="description" className="block text-xs font-medium text-gray-700">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={linkData.description}
                        onChange={handleChange}
                        className="mt-1 text-xs block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-xs"
                        rows="6"
                        disabled={loading}
                    />
                </div>
                <div className="col-span-full flex justify-end mt-4">
                    <button
                        type="submit"
                        className={`px-4 py-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            loading || submitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-emerald-500 hover:bg-emerald-600 focus:ring-emerald-500'
                        }`}
                        disabled={loading || submitting}
                    >
                        {submitting ? 'Submitting...' : 'Update Donation Link'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DonationLinkEdit;
